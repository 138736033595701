import "@aws-amplify/ui-react/styles.css";
import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Auth } from "aws-amplify";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import {
  getUserProfilePicture,
  uploadProfilePicture
} from "../utils/StorageUtil";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { createGlobalStyle } from "styled-components";

function Profile({ user }) {
  const [formData, setFormData] = useState({
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [profilePicture, setProfilePicture] = useState(null);
  const [setProfilePictureUrl] = useState(null);
  const [isGoogleUser, setIsGoogleUser] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      setFormData({ email: user.attributes.email });
      const profilePictureS3Link = await getUserProfilePicture(user);
      setProfilePictureUrl(profilePictureS3Link);
    }

    fetchUserData();

    // Check providers
    const identities = user.attributes.identities;
    if (identities && identities.length > 0) {
      const identitiesJson = JSON.parse(identities);
      for (const identity of identitiesJson) {
        if (identity.providerName === "Google") {
          setIsGoogleUser(true);
          console.log("isGoogleUser=true");
          break;
        }
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (profilePicture) {
        const profilePictureS3 = await uploadProfilePicture(profilePicture);
        if (!profilePictureS3) {
          alert("Unable to upload profile picture");
        }
        await Auth.updateUserAttributes(user, {
          "custom:profilePicture": profilePictureS3.key
        });
      }

      if (!isGoogleUser) {
        if (formData.newPassword !== formData.confirmPassword) {
          alert("New password and confirm password do not match");
          return;
        }
        if (formData.email) {
          await Auth.updateUserAttributes(user, { email: formData.email });
        }
        if (formData.currentPassword && formData.newPassword) {
          await Auth.changePassword(
            user,
            formData.currentPassword,
            formData.newPassword
          );
        }
      }

      alert("Your updates have been saved");
    } catch (err) {
      alert(err.message);
    }
  };
  const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
    .Audiowide {
        font-family: 'Audiowide', sans-serif;
    }
`;

  return (
    <>
      <GlobalStyle />
      <div
        style={{
          height: "100vh",
          marginBottom: "120px",
          display: "flex",
          justifyContent: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          boxSizing: "border-box"
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: "650px",
            marginTop: "40px",
            "@media (max-width: 499px)": {
              maxWidth: "350px",
              marginTop: "10px"
            }
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "#DEEBF8",
                "@media (max-width: 499px)": {
                  fontSize: "19px"
                }
              }}
              className="Audiowide"
              gutterBottom
            >
              EDIT PROFILE
            </Typography>

            <Typography
              variant="h5"
              align="left"
              sx={{
                fontWeight: "bold",
                color: "#DEEBF8",
                fontFamily: "Yantramanav",
                marginTop: "50px",
                "@media (max-width: 499px)": {
                  fontSize: "18px",
                  marginTop: "32px"
                }
              }}
            >
              Your Email
            </Typography>
            <form>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                value={formData.email}
                style={{ backgroundColor: "#DEEBF8" }}
                sx={{
                  "@media (max-width: 499px)": {
                    fontSize: "15px"
                  }
                }}
              />
            </form>

            {!isGoogleUser && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      color: "#DEEBF8",
                      marginBottom: "15px",
                      marginTop: "25px",
                      fontFamily: "Yantramanav",
                      "@media (max-width: 499px)": {
                        fontSize: "18px"
                      }
                    }}
                    gutterBottom
                  >
                    Change Your Password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Current Password"
                      type="password"
                      value={formData.currentPassword}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          currentPassword: event.target.value
                        })
                      }
                      variant="outlined"
                      style={{ backgroundColor: "#DEEBF8" }}
                      fullWidth
                      InputLabelProps={{
                        sx: {
                          fontSize: "17px", // Set the font size of the label
                          "@media (max-width: 499px)": {
                            fontSize: "15px" // Set the font size of the label for mobile screens
                          }
                        }
                      }}
                    />
                    <TextField
                      label="New Password"
                      type="password"
                      value={formData.newPassword}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          newPassword: event.target.value
                        })
                      }
                      variant="outlined"
                      style={{ backgroundColor: "#DEEBF8", marginTop: "20px" }}
                      fullWidth
                      InputLabelProps={{
                        sx: {
                          fontSize: "17px", // Set the font size of the label
                          "@media (max-width: 499px)": {
                            fontSize: "15px" // Set the font size of the label for mobile screens
                          }
                        }
                      }}
                    />
                    <TextField
                      label="Confirm Password"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          confirmPassword: event.target.value
                        })
                      }
                      required
                      variant="outlined"
                      style={{ backgroundColor: "#DEEBF8", marginTop: "20px" }}
                      fullWidth
                      InputLabelProps={{
                        sx: {
                          fontSize: "17px",
                          "@media (max-width: 499px)": {
                            fontSize: "15px"
                          }
                        }
                      }}
                    />
                  </form>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="left"
                sx={{
                  fontWeight: "bold",
                  color: "#DEEBF8",
                  fontFamily: "Yantramanav",
                  marginBottom: "15px",
                  marginTop: "25px",
                  "@media (max-width: 499px)": {
                    fontSize: "18px",
                    marginBottom: "15px"
                  }
                }}
                gutterBottom
              >
                Profile Picture
              </Typography>
              <input
                type="file"
                accept="image/*"
                style={{
                  color: "#deebf8",
                  "@media (max-width: 499px)": {
                    fontSize: "15px",
                    maxWidth: "30%"
                  }
                }}
                onChange={(event) => setProfilePicture(event.target.files[0])}
              />
              <br />
              <form onSubmit={handleSubmit}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleSubmit(e)}
                  sx={{
                    backgroundColor: "#5ce1e6",
                    color: "#282d35",
                    fontFamily: "Yantramanav, sans-serif",
                    fontSize: "17px",
                    marginTop: "20px",
                    transition: "background-color 0.2s ease-in-out",
                    "@media (max-width: 499px)": {
                      fontSize: "13.5px",
                      padding: "8px 10px"
                    }
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#a9edef";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#5ce1e6";
                  }}
                >
                  Update Profile
                </Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default withDayunAuthenticator(Profile);
