import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { getDeckMaterialUICardContent } from "../dayun-ui-compontents/card/BasicDeck";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CreateCardDialog from "../dayun-ui-compontents/dialog/CreateCardDialog";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import {
  searchLearningCardAndDeck,
  updateExistingCard
} from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";

function SearchPage({ signOut, user }) {
  const { term } = useParams();
  const [isSearching, setIsSearching] = useState(true);
  const [result, setResult] = useState(undefined);
  const [editCardDialogOpen, setEditCardDialogOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState(undefined);
  const navigate = useNavigate();

  function handleEditCard(card, props) {
    updateExistingCard(user.attributes.sub, card.deckId, card, props)
      .then((updatedCard) => {
        const newCards = result.cards.map((c) => {
          if (c.id === updatedCard.id) {
            return updatedCard;
          }
          return c;
        });
        setResult({
          decks: result.decks,
          cards: newCards
        });
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setEditCardDialogOpen(false);
        setCurrentCard(undefined);
      });
  }

  useEffect(() => {
    if (term) {
      searchLearningCardAndDeck(term, user.attributes.sub)
        .then((res) => {
          console.log("Search result", res);
          setResult(res);
          setIsSearching(false);
        })
        .catch((e) => {
          alertForApiError(e);
        });
    }
  }, [term, user]);

  function showDeckResult(result) {
    const decks = result && result.decks;
    if (decks && decks.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh",
            backgroundColor: "#282d35"
          }}
        >
          <Paper
            sx={{
              backgroundColor: "#deebf8",
              color: "#282d35",
              minWidth: 450,
              "@media (max-width: 499px)": {
                minWidth: 280
              }
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{
                fontSize: "29px",
                fontWeight: "bold",
                marginLeft: "13.5px",
                marginTop: "15px",
                "@media (max-width: 499px)": {
                  fontSize: "22.8px",
                  fontWeight: "bold",
                  marginLeft: "13.5px",
                  marginTop: "15px"
                }
              }}
            >
              Decks found:
            </Typography>
            <List>
              {decks &&
                decks.map((deck) => {
                  return (
                    <ListItem
                      key={deck.id}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Card
                        sx={{
                          width: "65%",
                          backgroundColor: "#deebf8",
                          color: "#282d35"
                        }}
                      >
                        {getDeckMaterialUICardContent(() => {
                          navigate(`/deck/${deck.id}`);
                        }, deck)}
                      </Card>
                    </ListItem>
                  );
                })}
            </List>
          </Paper>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh",
            backgroundColor: "#282d35"
          }}
        >
          <Paper
            sx={{
              backgroundColor: "#deebf8",
              color: "#282d35",
              minWidth: 450,
              "@media (max-width: 499px)": {
                minWidth: 280
              }
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{
                fontSize: "29px",
                fontWeight: "bold",
                marginLeft: "13.5px",
                marginTop: "15px",
                "@media (max-width: 499px)": {
                  fontSize: "22.8px",
                  fontWeight: "bold",
                  marginLeft: "13.5px",
                  marginTop: "15px"
                }
              }}
            >
              No decks found
            </Typography>
          </Paper>
        </div>
      );
    }
  }

  function renderQandA(row) {
    const question = row.question;
    const answer = row.answer;
    const questionImage = row.questionImage;
    return (
      <TableCell
        align="left"
        onClick={() => {
          setCurrentCard(row);
          setEditCardDialogOpen(true);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            {questionImage ? (
              <img
                src={questionImage}
                alt="Question"
                style={{ maxHeight: "100px" }}
              />
            ) : (
              question
            )}
          </div>
          <div
            style={{
              borderBottom: "1px solid black",
              flexGrow: 1,
              width: "100%"
            }}
          ></div>
          <div style={{ marginTop: "8px" }}>{answer}</div>
        </div>
      </TableCell>
    );
  }

  function getCardResult(result) {
    const cards = result && result.cards;
    if (cards && cards.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#282d35"
          }}
        >
          <Paper
            sx={{
              backgroundColor: "#deebf8",
              color: "#282d35",
              minWidth: 450,
              "@media (max-width: 499px)": {
                minWidth: 280
              }
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              sx={{
                fontSize: "29px",
                fontWeight: "bold",
                marginLeft: "13.5px",
                marginTop: "15px",
                "@media (max-width: 499px)": {
                  fontSize: "22.8px",
                  fontWeight: "bold",
                  marginLeft: "13.5px",
                  marginTop: "15px"
                }
              }}
            >
              Cards found:
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 450,
                  "@media (max-width: 499px)": {
                    minWidth: 280
                  }
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#deebf8",
                        color: "#282d35",
                        fontSize: "16px",
                        "@media (max-width: 499px)": {
                          fontSize: "14.5px"
                        }
                      }}
                    >
                      Deck name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: "#deebf8",
                        color: "#282d35",
                        fontSize: "16px",
                        "@media (max-width: 499px)": {
                          fontSize: "14.5px"
                        }
                      }}
                    >
                      Question/Answer
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cards.map((row) => (
                    <TableRow
                      key={row.id}
                      sddx={{
                        "&:last-child td, &:last-child th": { border: 0 }
                      }}
                      sx={{
                        backgroundColor: "#deebf8",
                        color: "#282d35",
                        fontSize: "16px",
                        "@media (max-width: 499px)": {
                          fontSize: "14.5px"
                        }
                      }}
                    >
                      <TableCell
                        sx={{
                          backgroundColor: "#deebf8",
                          color: "#282d35",
                          fontSize: "16px",
                          cursor: "pointer",
                          textDecoration: "underline",
                          "@media (max-width: 499px)": {
                            fontSize: "14.5px"
                          }
                        }}
                        component="th"
                        scope="row"
                        onClick={() => {
                          navigate(`/deck/${row.deckId}`);
                        }}
                      >
                        {row.deckName}
                      </TableCell>

                      {renderQandA(row)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      );
    }
  }

  function getSearchResult(result) {
    if (
      result === undefined ||
      (result.decks.length === 0 && result.cards.length === 0)
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh"
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{
              color: "#deebf8",
              textAlign: "center",
              "@media (max-width: 499px)": {
                fontSize: "24px"
              }
            }}
          >
            No result found
          </Typography>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "160px"
        }}
      >
        <div
          style={{
            width: "300px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {showDeckResult(result)}
          <div style={{ marginTop: "40px" }}>{getCardResult(result)}</div>
        </div>
        <CreateCardDialog
          currentCard={currentCard}
          onCreateCard={(props) => handleEditCard(currentCard, props)}
          onCloseDialog={() => setEditCardDialogOpen(false)}
          open={editCardDialogOpen}
        />
      </div>
    );
  }

  return (
    <div>
      {isSearching ? (
        <h2 style={{ color: "#deebf8" }}>Searching...</h2>
      ) : (
        getSearchResult(result)
      )}
    </div>
  );
}

export default withDayunAuthenticator(SearchPage);
