import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import Box from "@mui/material/Box";
import { DeckTabName, RoutePathName } from "../constant";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Share } from "@mui/icons-material";
import ShareDeckDialog from "../dayun-ui-compontents/dialog/ShareDeckDialog";

const categories = [
  {
    name: "Category 1",
    decks: [
      { id: 1, title: "Deck 1", description: "description of Deck 1" },
      { id: 2, title: "Deck 2", description: "description of Deck 2" },
      { id: 3, title: "Deck 3", description: "description of Deck 3" },
      { id: 4, title: "Deck 4", description: "description of Deck 4" },
      { id: 5, title: "Deck 5", description: "description of Deck 5" },
      { id: 6, title: "Deck 6", description: "description of Deck 6" }
    ]
  },
  {
    name: "Category 2",
    decks: [
      { id: 1, title: "Deck 1", description: "description of Deck 1" },
      { id: 2, title: "Deck 2", description: "description of Deck 2" },
      { id: 3, title: "Deck 3", description: "description of Deck 3" },
      { id: 4, title: "Deck 4", description: "description of Deck 4" }
    ]
  }
  // Add more categories here as needed
];

function PublicDecksDashboard({ signOut, user }) {
  const navigate = useNavigate();
  const [selectedTab] = useState(DeckTabName.PUBLIC_DECK_NAME);

  const handleDeckClick = (deckId) => {
    navigate(`/public-decks/${deckId}/cards`);
  };

  const goToOtherPageWhenClickingTab = (value) => {
    if (value !== DeckTabName.PUBLIC_DECK_NAME) {
      navigate(RoutePathName.DECKS);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleClickOpen = (event) => {
    event.stopPropagation();
    handleClose();
    setIsOpen(true);
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Grid
      sx={{
        paddingLeft: "240px",
        paddingRight: "240px",
        margin: "0 auto",
        "@media (max-width: 1281px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      }}
    >
      <Grid item xs={12}>
        <Box style={{ paddingTop: "15px", position: "relative" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => goToOtherPageWhenClickingTab(newValue)}
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#5271ff" },
              "& .MuiTab-root": { color: "#deebf8" },
              "& .Mui-selected": { color: "#5ce1e6" }
            }}
          >
            <Tab
              label="My Decks"
              value="My Decks"
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "24px",
                marginLeft: "23px",
                marginTop: "15px",
                "@media (max-width: 541px)": {
                  fontSize: "16.5px",
                  width: "38%",
                  height: "40px",
                  marginTop: "5px",
                  justifyContent: "center",
                  marginLeft: "10px"
                }
              }}
            />
            <Tab
              label="Public Decks"
              value="Public Decks"
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "23px",
                marginLeft: "22px",
                marginTop: "15px",
                "@media (max-width: 541px)": {
                  fontSize: "16.5px",
                  width: "45%",
                  height: "40px",
                  marginTop: "5px",
                  justifyContent: "center",
                  position: "absolute",
                  left: "140px"
                }
              }}
            />
          </Tabs>
          {categories.map((category, index) => (
            <Grid container spacing={1} key={index}>
              <Grid item xs={12} marginLeft={5} marginTop={6}>
                <Typography
                  variant="h4"
                  component="h4"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#deebf8",
                    "@media (max-width: 541px)": {
                      fontSize: "17px"
                    }
                  }}
                >
                  {category.name}
                </Typography>
              </Grid>
              {category.decks.map((deck, index) => (
                <Grid
                  container
                  justifyContent="left"
                  alignItems="left"
                  item
                  xs={12}
                  md={4}
                  sm={3}
                  lg={2}
                  xl={2}
                  marginLeft={5}
                  marginRight={0.2}
                  padding={{ xs: 1, md: 2 }}
                  key={deck.id}
                  marginTop={1}
                  sx={{
                    "@media (max-width: 541px)": {
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridGap: "10px",
                      padding: "17px",
                      marginRight: "24px"
                    }
                  }}
                >
                  <Card
                    onClick={() => handleDeckClick(deck.id)}
                    sx={{
                      backgroundColor: "#deebf8",
                      padding: "10px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#b5c5e5"
                      },
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px"
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleMenuOpen}
                      style={{ float: "right" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleClickOpen}>
                        <Share
                          style={{ marginRight: "0.5em", color: "#282d35" }}
                        />
                        Share
                      </MenuItem>
                    </Menu>
                    <ShareDeckDialog
                      open={isOpen}
                      onCloseDialog={handleClose}
                    />
                    <Typography
                      variant="h4"
                      component="h4"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                        color: "#282d35",
                        borderBottom: "3px solid #b5c5e5",
                        paddingBottom: "10px",
                        "@media (max-width: 541px)": {
                          fontSize: "17px"
                        }
                      }}
                    >
                      {deck.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      gutterBottom
                      sx={{
                        color: "#282d35",
                        "@media (max-width: 541px)": {
                          fontSize: "14px"
                        }
                      }}
                    >
                      {deck.description}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default withDayunAuthenticator(PublicDecksDashboard);
