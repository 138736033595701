import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { RoutePathName } from "../../constant";

export default function ShareDeckDialog({
  open,
  onClickShare,
  onCloseDialog,
  deck
}) {
  const [link, setLink] = useState(window.location.href);
  useEffect(() => {
    if (deck === undefined) {
      return;
    }
    setLink(window.location.origin + RoutePathName.DECK + "/" + deck.id);
  }, [deck]);

  useEffect(() => {
    const handleStopPropagation = (event) => {
      event.stopPropagation();
    };
    document.addEventListener("click", handleStopPropagation);

    return () => {
      document.removeEventListener("click", handleStopPropagation);
    };
  }, [open]);

  const handleCopyLink = (event) => {
    navigator.clipboard.writeText(link);
    if (onClickShare) {
      onClickShare();
    }
    event.stopPropagation();
  };

  const closeDialog = (event) => {
    event.stopPropagation();
    onCloseDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container sx={{ background: "#deebf8" }}>
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            color: "#282d35"
          }}
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            background: "#deebf8",
            color: "#282d35",
            fontSize: "31px",
            fontWeight: "bold",
            textAlign: "center",
            "@media (max-width: 499px)": {
              fontSize: "25px"
            }
          }}
        >
          Share this deck
        </DialogTitle>
        <DialogContent style={{ background: "#deebf8", color: "#282d35" }}>
          <TextField
            autoFocus
            margin="dense"
            label="Link"
            value={link}
            fullWidth
            readOnly
          />
        </DialogContent>
        <DialogActions style={{ background: "#deebf8" }}>
          <Button onClick={handleCopyLink}>Copy link</Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
}
