/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGeneralDeck = /* GraphQL */ `
  mutation CreateGeneralDeck(
    $input: CreateGeneralDeckInput!
    $condition: ModelGeneralDeckConditionInput
  ) {
    createGeneralDeck(input: $input, condition: $condition) {
      id
      name
      description
      subject
      tags
      isShared
      isSharedEditable
      owners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGeneralDeck = /* GraphQL */ `
  mutation UpdateGeneralDeck(
    $input: UpdateGeneralDeckInput!
    $condition: ModelGeneralDeckConditionInput
  ) {
    updateGeneralDeck(input: $input, condition: $condition) {
      id
      name
      description
      subject
      tags
      isShared
      isSharedEditable
      owners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGeneralDeck = /* GraphQL */ `
  mutation DeleteGeneralDeck(
    $input: DeleteGeneralDeckInput!
    $condition: ModelGeneralDeckConditionInput
  ) {
    deleteGeneralDeck(input: $input, condition: $condition) {
      id
      name
      description
      subject
      tags
      isShared
      isSharedEditable
      owners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGeneralCard = /* GraphQL */ `
  mutation CreateGeneralCard(
    $input: CreateGeneralCardInput!
    $condition: ModelGeneralCardConditionInput
  ) {
    createGeneralCard(input: $input, condition: $condition) {
      id
      deckId
      question
      answer
      owners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGeneralCard = /* GraphQL */ `
  mutation UpdateGeneralCard(
    $input: UpdateGeneralCardInput!
    $condition: ModelGeneralCardConditionInput
  ) {
    updateGeneralCard(input: $input, condition: $condition) {
      id
      deckId
      question
      answer
      owners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGeneralCard = /* GraphQL */ `
  mutation DeleteGeneralCard(
    $input: DeleteGeneralCardInput!
    $condition: ModelGeneralCardConditionInput
  ) {
    deleteGeneralCard(input: $input, condition: $condition) {
      id
      deckId
      question
      answer
      owners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMyLearningDeck = /* GraphQL */ `
  mutation CreateMyLearningDeck(
    $input: CreateMyLearningDeckInput!
    $condition: ModelMyLearningDeckConditionInput
  ) {
    createMyLearningDeck(input: $input, condition: $condition) {
      userId
      deckId
      numCardsPerRound
      fontSize
      fontColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateMyLearningDeck = /* GraphQL */ `
  mutation UpdateMyLearningDeck(
    $input: UpdateMyLearningDeckInput!
    $condition: ModelMyLearningDeckConditionInput
  ) {
    updateMyLearningDeck(input: $input, condition: $condition) {
      userId
      deckId
      numCardsPerRound
      fontSize
      fontColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteMyLearningDeck = /* GraphQL */ `
  mutation DeleteMyLearningDeck(
    $input: DeleteMyLearningDeckInput!
    $condition: ModelMyLearningDeckConditionInput
  ) {
    deleteMyLearningDeck(input: $input, condition: $condition) {
      userId
      deckId
      numCardsPerRound
      fontSize
      fontColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createMyLearningCard = /* GraphQL */ `
  mutation CreateMyLearningCard(
    $input: CreateMyLearningCardInput!
    $condition: ModelMyLearningCardConditionInput
  ) {
    createMyLearningCard(input: $input, condition: $condition) {
      userId
      deckId
      cardId
      recentSuperMemoGrade
      interval
      efactor
      repetition
      superMemoStartDateTime
      firstReviewDateTime
      lastLearningDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateMyLearningCard = /* GraphQL */ `
  mutation UpdateMyLearningCard(
    $input: UpdateMyLearningCardInput!
    $condition: ModelMyLearningCardConditionInput
  ) {
    updateMyLearningCard(input: $input, condition: $condition) {
      userId
      deckId
      cardId
      recentSuperMemoGrade
      interval
      efactor
      repetition
      superMemoStartDateTime
      firstReviewDateTime
      lastLearningDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteMyLearningCard = /* GraphQL */ `
  mutation DeleteMyLearningCard(
    $input: DeleteMyLearningCardInput!
    $condition: ModelMyLearningCardConditionInput
  ) {
    deleteMyLearningCard(input: $input, condition: $condition) {
      userId
      deckId
      cardId
      recentSuperMemoGrade
      interval
      efactor
      repetition
      superMemoStartDateTime
      firstReviewDateTime
      lastLearningDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
