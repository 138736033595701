import * as React from "react";
import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ProgressDialog from "./ProgressDialog";
import spreadsheeticon from "./spreadsheeticon.png";
import ankiicon from "./ankiicon.png";
import { Alert, Container, useMediaQuery, useTheme } from "@mui/material";
import * as CardImportUtil from "../../utils/ImportCardUtil";
import {
  DEFAULT_CARDS_TO_LEARN_PER_ROUND,
  IMPORT_SOURCE_SPREADSHEET,
  IMPORT_SOURCE_TXT
} from "../../constant";
import * as ApiUtil from "../../utils/ApiUtil";
import { addNewCard } from "../../utils/ApiUtil";
import DialogTitle from "@mui/material/DialogTitle";

const CommonButtonStyle = {
  backgroundColor: "#deebf8",
  color: "#282d35",
  fontWeight: "bold",
  transition: "background-color 0.2s ease-in-out",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxShadow: "2px 2px 10px rgba(0,0,0,0.3)",
  marginBottom: "30px",
  marginRight: "36px",
  marginLeft: "20px",
  padding: "50px",
  fontSize: "16px"
};

export default function ImportCardDialog({
  open,
  currentDeck,
  onCloseDialog,
  currentCardSize,
  user,
  currentDeckSize,
  onDeckImported
}) {
  const [processProgress, setProcessProcessProgress] = useState(false);
  const [importSource, setImportSource] = useState("");
  const [importSuccess, setImportSuccess] = useState(false);
  const spreadsheetTextFieldRef = useRef(null);
  const txtTextFieldRef = useRef(null);
  const subjectRef = useRef(null);
  const deckNameRef = useRef(null);
  const theme = useTheme();
  const isMatchSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOptionSelection = (selectedOption) => {
    setImportSource(selectedOption);
  };

  const closeDialog = () => {
    onCloseDialog();
    setTimeout(() => {
      setProcessProcessProgress(false);
      setImportSuccess(false);
      setImportSource("");
    }, 500);
  };

  const handleImport = () => {
    let textFieldValue = "";
    if (importSource === IMPORT_SOURCE_SPREADSHEET) {
      textFieldValue = spreadsheetTextFieldRef.current.value;
    } else if (importSource === IMPORT_SOURCE_TXT) {
      textFieldValue = txtTextFieldRef.current.value;
    }
    if (textFieldValue === "") {
      alert("Please enter the content of the file");
      return;
    }
    if (subjectRef.current.value === "") {
      alert("Please enter the subject of the deck");
      return;
    }
    if (deckNameRef.current.value === "") {
      alert("Please enter the name of the deck");
      return;
    }

    const cards = CardImportUtil.parseTxtOrCsvContent(textFieldValue);
    if (cards === undefined || cards.length === 0) {
      alert("No card found in the file. Please check the content and try again. " +
        "Each pair  question,answer should be separated by a comma or tab.")
      return;
    }
    setProcessProcessProgress(true);
    const createDeckPromise = () => {
      if (currentDeck) {
        return Promise.resolve(currentDeck);
      } else {
        const newDeck = {
          subject: subjectRef.current.value,
          name: deckNameRef.current.value,
          numCardsPerRound: DEFAULT_CARDS_TO_LEARN_PER_ROUND
        };
        return ApiUtil.addNewDeck(newDeck, user, currentDeckSize);
      }
    };

    createDeckPromise()
      .then((deck) => {
        const addCardPromises = cards.map((card, index) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              addNewCard(card, deck.id, deck.userId, currentCardSize)
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            }, index * 100); // set delay between API calls to 0.1s
          });
        });
        Promise.all(addCardPromises)
          .then(() => {
            setProcessProcessProgress(false);
            setImportSuccess(true);
            if (onDeckImported !== undefined) {
              onDeckImported(deck, cards);
            }
            setTimeout(() => {
              closeDialog();
            }, 1000);
          })
          .catch((error) => {
            setProcessProcessProgress(false);
            alert("Import failed: " + error);
            closeDialog();
          });
      })
      .catch((error) => {
        setProcessProcessProgress(false);
        alert("Import failed: " + error);
      });
  };

  const showImportSuccessAlert = () => {
    return (
      importSuccess && (
        <Alert severity="success" style={{ margin: "10px" }}>
          Import successfully!
        </Alert>
      )
    );
  };

  function renderInputText() {
    return <>
      <TextField
        label="Subject"
        style={{
          background: "#deebf8",
          color: "#282d35",
          display: "flex",
          alignItems: "left",
          borderRadius: "5px",
          marginBottom: "25px"
        }}
        inputRef={subjectRef}
      />
      <TextField
        variant="outlined"
        label="Create a deck name"
        style={{
          background: "#deebf8",
          color: "#282d35",
          display: "flex",
          alignItems: "left",
          borderRadius: "5px",
          marginBottom: "25px"
        }}
        inputRef={deckNameRef}
      />
    </>;
  }

  return (
    <div>
      <ProgressDialog open={processProgress} title={"Loading..."} />
      <Dialog open={open} onClose={closeDialog}>
        <Container style={{ background: "#282d35" }}>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "#deebf8"
            }}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ background: "#282d35", padding: 0 }}>
            {importSource === IMPORT_SOURCE_SPREADSHEET && (
              <div style={{ textAlign: "center" }}>
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "25px",
                    color: "#deebf8",
                    fontSize: "31px",
                    "@media (max-width: 499px)": {
                      fontSize: "25px"
                    }
                  }}
                >
                  Instructions:
                </DialogTitle>
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "#deebf8",
                    borderRadius: "5px",
                    marginBottom: "25px"
                  }}
                >
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#282d35"
                    }}
                  >
                    For 1 column:
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "18px",
                      color: "#282d35"
                    }}
                  >
                    each row represents a question.
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#282d35"
                    }}
                  >
                    For 2 columns:
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "18px",
                      color: "#282d35"
                    }}
                  >
                    each row represents a question and answer pair.{" "}
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "18px",
                      color: "#282d35"
                    }}
                  >
                    The first column is for the question, and the second column
                    is for the answer.
                  </p>
                </div>
                <TextField
                  multiline
                  rows={10}
                  variant="outlined"
                  label="Paste your Spreadsheet data here"
                  style={{
                    background: "#deebf8",
                    color: "#282d35",
                    display: "flex",
                    alignItems: "left",
                    borderRadius: "5px",
                    marginBottom: "25px"
                  }}
                  inputRef={spreadsheetTextFieldRef}
                />
                {renderInputText()}>
              </div>
            )}
            {importSource === IMPORT_SOURCE_TXT && (
              <div>
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "25px",
                    color: "#deebf8",
                    fontSize: "31px",
                    "@media (max-width: 499px)": {
                      fontSize: "25px"
                    }
                  }}
                >
                  Instructions:
                </DialogTitle>
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "#deebf8",
                    borderRadius: "5px",
                    marginBottom: "25px"
                  }}
                >
                  <DialogContent
                    sx={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#282d35",
                      "@media (max-width: 499px)": {
                        fontSize: "16px"
                      }
                    }}
                  >
                    Export your deck from Anki as "Notes in Plain Text (*.txt)"
                  </DialogContent>
                  <DialogContent
                    sx={{
                      textAlign: "left",
                      fontSize: "18px",
                      color: "#282d35",
                      "@media (max-width: 499px)": {
                        fontSize: "16px"
                      }
                    }}
                  >
                    After that, copy the text from the file and paste it into
                    the box below:
                  </DialogContent>
                </div>
                <TextField
                  multiline
                  rows={10}
                  variant="outlined"
                  label="Paste the text here"
                  style={{
                    background: "#deebf8",
                    color: "#282d35",
                    display: "flex",
                    alignItems: "left",
                    borderRadius: "5px",
                    marginBottom: "25px"
                  }}
                  inputRef={txtTextFieldRef}
                />
                {renderInputText()}
              </div>
            )}
          </DialogContent>
          {importSource === "" && (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#282d35",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <DialogTitle
                sx={{
                  color: "#deebf8",
                  fontWeight: "bold",
                  marginTop: "30px",
                  fontSize: "31px",
                  "@media (max-width: 499px)": {
                    fontSize: "25px"
                  }
                }}
              >
                Import from
              </DialogTitle>
              <div
                style={
                  isMatchSmallScreen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px"
                      }
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Button
                    onClick={() =>
                      handleOptionSelection(IMPORT_SOURCE_SPREADSHEET)
                    }
                    style={{
                      ...CommonButtonStyle
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#b3cde0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#deebf8";
                    }}
                  >
                    <img
                      src={spreadsheeticon}
                      alt="Spreadsheet"
                      style={{
                        maxWidth: "100px",
                        height: "100px",
                        marginRight: "10px",
                        objectFit: "cover"
                      }}
                    />
                    <span style={{ fontWeight: "bold", marginTop: "5px" }}>
                      Spreadsheet
                    </span>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Button
                    onClick={() => handleOptionSelection(IMPORT_SOURCE_TXT)}
                    style={{
                      ...CommonButtonStyle
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#b3cde0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#deebf8";
                    }}
                  >
                    <img
                      src={ankiicon}
                      alt="Anki"
                      style={{
                        maxWidth: "100px",
                        height: "100px",
                        marginRight: "10px",
                        objectFit: "cover"
                      }}
                    />
                    <span style={{ fontWeight: "bold", marginTop: "2px" }}>
                      Anki
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          )}
          {importSource !== "" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              {showImportSuccessAlert()}
              <Button
                onClick={handleImport}
                style={{
                  marginBottom: "20px",
                  fontWeight: "bold",
                  transition: "background-color 0.2s ease-in-out",
                  backgroundColor: "#5ce1e6",
                  color: "#282d35",
                  fontFamily: "Yantramanav, sans-serif",
                  fontSize: "16px",
                  width: "150px"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#b2f5f7";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#5ce1e6";
                }}
              >
                Import
              </Button>
            </div>
          )}
        </Container>
      </Dialog>
    </div>
  );
}
