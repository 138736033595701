import React, { useEffect, useState } from "react";
import {
  alpha,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputBase,
  styled,
  TextField,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import logo from "../../assets/logo.png";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { APP_NAME, RoutePathName } from "../../constant";
import { Auth, DataStore, Hub } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import AccountMenu from "./AccountMenu";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "23ch",
      "&:focus": {
        width: "25ch"
      }
    }
  }
}));

const Navbar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Searching functionality
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      navigate(`/search/${searchTerm.trim()}`);
    }
    setOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchTerm.trim() !== "") {
      navigate(`/search/${searchTerm.trim()}`);
    }
  };

  useEffect(() => {
    let updateUser = async (data) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch {
        console.log("Unable to get user");
        setUser(null);
      }

      if (data && data.payload.event === "signIn") {
        console.log("Signing in");
        navigate(RoutePathName.DECKS);
      }

      if (data && data.payload.event === "signOut") {
        console.log("Signing out");
        navigate(RoutePathName.HOME);
        try {
          console.log("Cleaning cache");
          await DataStore.clear();
        } catch (e) {
          console.log("Error when cleaning cache", e);
        }
      }
    };
    updateUser();
    return Hub.listen("auth", updateUser); // listen for login/signup events
  }, []);

  async function onClickLogout() {
    await Auth.signOut();
  }

  async function onClickLogin() {
    navigate(RoutePathName.DECKS);
  }

  function getSearchUi() {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isMobile ? (
          <>
            <IconButton onClick={() => setOpen(true)}>
              <SearchIcon />
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle
                sx={{
                  color: "#deebf8",
                  fontWeight: "bold",
                  backgroundColor: "#282d35",
                  textAlign: "center",
                  fontSize: "24px",
                  "@media (max-width: 499px)": {
                    fontSize: "20px"
                  }
                }}
              >
                Enter a search term:
              </DialogTitle>
              <Box sx={{ p: 2, backgroundColor: "#282d35" }}>
                <TextField
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  sx={{
                    marginTop: "2px",
                    marginBottom: "18px",
                    color: "#282d35",
                    backgroundColor: "#deebf8",
                    textAlign: "left",
                    fontSize: "22px",
                    "@media (max-width: 499px)": {
                      fontSize: "20px"
                    }
                  }}
                />
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button
                    onClick={() => setOpen(false)}
                    variant="contained"
                    sx={{
                      backgroundColor: "#5ce1e6",
                      color: "#282d35",
                      fontWeight: "bold",
                      transition: "background-color 0.2s ease-in-out"
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#a9edef";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#5ce1e6";
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSearch}
                    variant="contained"
                    sx={{
                      ml: 2,
                      backgroundColor: "#5ce1e6",
                      color: "#282d35",
                      fontWeight: "bold",
                      transition: "background-color 0.2s ease-in-out"
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#a9edef";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#5ce1e6";
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </>
        ) : (
          <Search
            sx={{ marginLeft: "auto", color: "#5271ff" }}
            variant="contained"
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Search decks and cards…"
              inputProps={{ "aria-label": "search" }}
              sx={{
                "@media (max-width: 499px)": {
                  placeholder: {
                    display: "none"
                  }
                }
              }}
            />
          </Search>
        )}
      </Box>
    );
  }
  return (
    <React.Fragment>
      <AppBar
        sx={{
          background: "#deebf8",
          height: "70px",
          "@media (max-width: 599px)": {
            height: "56px"
          }
        }}
        position="sticky"
      >
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <IconButton
              disableRipple
              aria-label={APP_NAME}
              onClick={() =>
                navigate(user ? RoutePathName.DECKS : RoutePathName.HOME)
              }
            >
              <img
                className="logo"
                src={logo}
                alt="logo"
                style={{
                  background: "none"
                }}
              />
            </IconButton>
          </Box>
          {user ? (
            <>
              {getSearchUi()}
              <Button
                color={"primary"}
                onClick={() => {
                  navigate(RoutePathName.DECKS);
                }}
                sx={{
                  marginLeft: "auto",
                  color: "#deebf8",
                  fontWeight: "bold",
                  fontSize: "17px",
                  backgroundColor: "#5271ff",
                  "@media (max-width: 599px)": {
                    fontSize: "11.5px"
                  },
                  "&:hover": {
                    backgroundColor: "rgba(82, 113, 255, 0.9)"
                  }
                }}
              >
                Decks
              </Button>
              <AccountMenu user={user} singOut={onClickLogout} />
            </>
          ) : (
            <>
              {
                <Button
                  onClick={() => navigate(RoutePathName.CONTACT)}
                  sx={{
                    marginRight: "10px",
                    color: "#deebf8",
                    fontWeight: "bold",
                    fontSize: "15px",
                    backgroundColor: "#5271ff",
                    "@media (max-width: 599px)": {
                      fontSize: "11.5px"
                    },
                    "&:hover": {
                      backgroundColor: "rgba(82, 113, 255, 0.9)"
                    }
                  }}
                >
                  Contact Us
                </Button>
              }

              <Button
                onClick={onClickLogin}
                sx={{
                  color: "#deebf8",
                  fontWeight: "bold",
                  fontSize: "15px",
                  backgroundColor: "#5271ff",
                  "@media (max-width: 599px)": {
                    fontSize: "11.5px"
                  },
                  "&:hover": {
                    backgroundColor: "rgba(82, 113, 255, 0.9)"
                  }
                }}
              >
                Log in
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;
