/***
 *  item: SuperMemoItem = {
 *   interval: number; the number of continous correct responses. The initial repetition value should be 0.
 *   repetition: number; inter-repetition interval after the repetitions (in days). The initial interval value should be 0.
 *   efactor: number; easiness factor reflecting the easiness of memorizing and retaining a given item in memory. The initial efactor value should be 2.5.
 * };
 * @param grade Grade must be between 0 and 5
 * 5: perfect response.
 * 4: correct response after a hesitation.
 * 3: correct response recalled with serious difficulty.
 * 2: incorrect response; where the correct one seemed easy to recall.
 * 1: incorrect response; the correct one remembered.
 * 0: complete blackout.
 * @returns {{interval: number, repetition: number, efactor: number}}
 */
export function supermemo(item, grade) {
  if (item.repetition === undefined || item.repetition === null) {
    item.repetition = 0;
  }
  if (item.interval === undefined || item.interval === null) {
    item.interval = 0;
  }
  if (item.efactor === undefined || item.efactor === null) {
    item.efactor = 2.5;
  }
  if (grade > 5 || grade < 0) {
    throw new Error("Grade must be between 0 and 5");
  }
  let nextInterval;
  let nextRepetition;
  let nextEfactor;

  if (grade >= 3) {
    if (item.repetition === 0) {
      nextInterval = 1;
      nextRepetition = 1;
    } else if (item.repetition === 1) {
      nextInterval = 6;
      nextRepetition = 2;
    } else {
      nextInterval = Math.round(item.interval * item.efactor);
      nextRepetition = item.repetition + 1;
    }
  } else {
    nextInterval = 1;
    nextRepetition = 0;
  }

  nextEfactor =
    item.efactor + (0.1 - (5 - grade) * (0.08 + (5 - grade) * 0.02));

  if (nextEfactor < 1.3) nextEfactor = 1.3;

  nextEfactor = Math.round(nextEfactor * 100) / 100;
  return {
    interval: nextInterval,
    repetition: nextRepetition,
    efactor: nextEfactor
  };
}
