import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { createGlobalStyle } from "styled-components";
import "./Profile.css";
import "./Privacy.css";

function Privacy() {
  const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
    .Audiowide {
        font-family: 'Audiowide', sans-serif;
    }
`;
  return (
    <>
      <GlobalStyle />
      <div
        style={{
          height: "100vh",
          marginBottom: "120px",
          display: "flex",
          justifyContent: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          boxSizing: "border-box"
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: "650px",
            marginTop: "40px",
            color: "#DEEBF8",
            "@media (max-width: 499px)": {
              maxWidth: "350px",
              marginTop: "10px"
            }
          }}
        >
          <Grid item xs={12}>
            <Typography
              className="heading"
              variant="h3"
              align="center"
              fontWeight="bold"
              gutterBottom
            >
              FlashLearn Privacy Policy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to FlashLearn, a web app designed to help you learn and
              memorize information using flashcards. At FlashLearn, we
              understand the importance of protecting your personal information
              and are committed to ensuring your privacy. This privacy policy
              explains how we collect, use, and protect the information you
              provide us when using our service. FlashLearn is designed to
              collect certain types of data from its users in order to provide a
              personalized learning experience. We use this data to improve the
              app's functionality and to provide you with the best possible
              service. However, we recognize that this data is sensitive and
              understand the need to keep it secure and confidential. We take
              our responsibilities under applicable privacy laws seriously and
              strive to maintain the highest standards of data protection. This
              privacy policy sets out the principles that we follow when
              collecting, using, and protecting your personal information. Thank
              you for choosing FlashLearn as your learning tool. We are
              committed to your success and to ensuring your privacy.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Types of data collected
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn collects two types of data: personal information and
              usage data.
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Personal Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Username and password</li>
                <li>Any other information you choose to provide us</li>
              </ul>
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Usage Data
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>Pages visited on the app</li>
                <li>Time spent on the app</li>
                <li>Search queries</li>
                <li>Flashcards studied</li>
                <li>
                  Study performance data (such as score, accuracy, and time
                  taken to complete a study session)
                </li>
                <li>Other usage statistics necessary to improve our service</li>
              </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn may also collect information from your device or
              browser, such as your IP address, browser type, and operating
              system. This information is used for technical purposes and to
              improve the app's functionality.
            </Typography>
            <Typography variant="body1" gutterBottom>
              By using FlashLearn, you consent to the collection and use of your
              data as outlined in this privacy policy. If you do not agree with
              any aspect of this policy, please do not use our service.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Data Usage
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn uses the data we collect for the following purposes:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>
                  To provide and improve the service: We use the data to operate
                  and maintain FlashLearn, to personalize your experience, and
                  to improve our service's functionality.
                </li>
                <li>
                  To communicate with you: We use your email address to send you
                  transactional messages (such as account creation and password
                  reset emails) and promotional messages (such as newsletters
                  and new feature announcements). You can opt out of promotional
                  messages at any time by clicking the unsubscribe link at the
                  bottom of the email.
                </li>
                <li>
                  To personalize your experience: We use the data we collect to
                  tailor the content and recommendations that you see on
                  FlashLearn to your interests and study history.
                </li>
                <li>
                  To conduct research: We may use aggregated and anonymized data
                  to conduct research and to develop and improve our service.
                  This data does not identify individual users.
                </li>
                <li>
                  To provide customer support: We may use your data to provide
                  customer support, such as helping you troubleshoot technical
                  issues or answering questions about the service.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
              We will not use your data for any other purpose without your
              consent. We may also de-identify or aggregate your data to create
              non-personalized data sets for our internal use or for external
              research purposes.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please note that FlashLearn does not sell or rent your personal
              information to any third-party companies. We may share your data
              with third-party service providers who assist us in providing the
              service, such as payment processors or hosting providers. However,
              these providers are contractually bound to protect your data and
              use it only for the purposes of providing the service to us.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Data Sharing
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn may share your personal information with third parties
              in the following circumstances:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>
                  Service Providers: We may share your personal information with
                  third-party service providers that help us operate our
                  business, such as hosting providers, payment processors, and
                  customer support providers. These service providers are
                  authorized to use your personal information only as necessary
                  to provide services to us.
                </li>
                <li>
                  Business Transfers: We may share your personal information in
                  connection with a merger, acquisition, or other business
                  transaction involving FlashLearn. In such cases, your personal
                  information may be transferred to a third party as a business
                  asset in the transaction. We will take appropriate steps to
                  protect your personal information in such transactions.
                </li>
                <li>
                  Legal Requirements: We may disclose your personal information
                  if required to do so by law or in response to a valid legal
                  request, such as a subpoena or court order.
                </li>
                <li>
                  Safety and Security: We may disclose your personal information
                  to protect the safety and security of FlashLearn, our users,
                  and the public. This includes sharing information with law
                  enforcement agencies or other third parties as necessary to
                  prevent or address fraud, security, or technical issues.
                </li>
                <li>
                  With Your Consent: We may share your personal information with
                  third parties with your consent.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn does not sell your personal information to third
              parties.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please note that if you interact with third-party websites or
              services through FlashLearn, your personal information may be
              subject to the privacy policies of those third parties. We
              encourage you to review the privacy policies of those third
              parties before providing them with your personal information.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Security
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn takes the security of your personal information
              seriously and employs reasonable and appropriate measures to
              protect it from unauthorized access, use, or disclosure. We use
              industry-standard security technologies and procedures to help
              safeguard your data.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We store all user data on secure servers hosted by reputable
              third-party hosting providers. Our servers are protected by
              firewalls and other security measures designed to prevent
              unauthorized access.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We also use encryption to protect your sensitive data, such as
              passwords and payment information. However, please note that no
              method of transmission over the internet or method of electronic
              storage is 100% secure. Therefore, while we strive to use
              commercially acceptable means to protect your personal
              information, we cannot guarantee its absolute security.
            </Typography>
            <Typography variant="body1" gutterBottom>
              In the event of a data breach, we will notify affected users as
              soon as possible and take all necessary steps to remediate the
              breach and prevent future incidents.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We also ask that you do your part to help protect your personal
              information. Please keep your login credentials (such as username
              and password) secure and do not share them with others. If you
              suspect that your account has been compromised, please contact us
              immediately at [contact email] so that we can take appropriate
              action.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Finally, we recommend that you use up-to-date virus protection
              software and keep your device's operating system and browser up to
              date to help protect your personal information.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Cookies and Tracking
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn uses cookies and similar tracking technologies to
              improve and personalize your experience on the app. Cookies are
              small data files that are placed on your device when you visit a
              website or use an app. They allow us to recognize your device and
              remember your preferences and settings.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We use cookies for the following purposes:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>
                  Authentication: We use cookies to keep you logged in to your
                  account and to remember your preferences, such as your
                  language and study settings.
                </li>
                <li>
                  Analytics: We use cookies to collect usage data, such as pages
                  visited, time spent on the app, and search queries, to improve
                  our service's functionality and user experience.
                </li>
                <li>
                  Advertising: We use cookies to display targeted advertisements
                  to you based on your interests and behavior on the app.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn also uses third-party analytics and advertising
              providers, such as Google Analytics and Google AdSense, which may
              also use cookies and similar technologies to collect usage data
              and display targeted advertisements to you. These providers may
              have their own privacy policies governing the use of cookies and
              similar technologies.
            </Typography>
            <Typography variant="body1" gutterBottom>
              You can control cookies and similar tracking technologies through
              your device or browser settings. Most browsers allow you to refuse
              cookies or to set preferences for how cookies are used. However,
              please note that disabling cookies may limit your ability to use
              certain features of the app.
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn also uses pixel tags and web beacons, which are small
              images embedded in web pages and emails, to collect usage data and
              to track the effectiveness of our marketing campaigns. These
              technologies do not collect any personal information and are used
              for analytical and marketing purposes only.
            </Typography>
            <Typography variant="body1" gutterBottom>
              By using FlashLearn, you consent to the use of cookies and similar
              tracking technologies as outlined in this privacy policy. If you
              do not agree with any aspect of this policy, please do not use our
              service.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              User Rights
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn respects your rights as a user and provides you with
              the following options for controlling your personal information:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                <li>
                  Access and Correction: You have the right to access and
                  correct your personal information held by FlashLearn. You can
                  do so by logging in to your account and editing your profile
                  information. If you need further assistance, please contact us
                  via Contact Form.
                </li>
                <li>
                  Deletion: You have the right to request the deletion of your
                  personal information from FlashLearn's servers. You can do so
                  by contacting us. Please note that we may need to retain
                  certain data for legal or administrative purposes, such as to
                  comply with our legal obligations or to resolve disputes.
                </li>
                <li>
                  Object: You have the right to object to the processing of your
                  personal information for certain purposes, such as for
                  marketing or advertising purposes. You can do so by contacting
                  us via Contact Form.
                </li>
                <li>
                  Restrict: You have the right to restrict the processing of
                  your personal information in certain circumstances, such as
                  when you contest the accuracy of your personal information or
                  when the processing is unlawful. You can do so by contacting
                  us via Contact Form.
                </li>
                <li>
                  Data Portability: You have the right to receive a copy of your
                  personal information in a structured, commonly used, and
                  machine-readable format, and to transmit that data to another
                  data controller. You can do so by contacting us via Contact
                  Form.
                </li>
                <li>
                  Withdraw Consent: You have the right to withdraw your consent
                  to the processing of your personal information at any time.
                  You can do so by contacting us via Contact Form.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please note that we may require additional information from you to
              verify your identity before fulfilling your request. We will
              respond to your request as soon as possible and in accordance with
              applicable law.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you have any questions or concerns regarding your rights as a
              user, please use the contact form to get in touch with us.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Contact Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you have any questions or concerns regarding this privacy
              policy or FlashLearn's data practices, please use the contact form
              to get in touch with us. We will respond to your inquiry as soon
              as possible.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you are located in the European Union, you have the right to
              lodge a complaint with a supervisory authority if you believe that
              FlashLearn has violated your rights under applicable data
              protection laws. A list of supervisory authorities is available on
              the European Commission's website.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We encourage you to periodically review this privacy policy to
              stay informed about our data practices. We may update this policy
              from time to time to reflect changes in our data practices or
              applicable law. If we make material changes to this policy, we
              will provide you with notice by email or by posting a notice on
              our website.
            </Typography>
            <Typography
              className="subheading"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Updates to the Privacy Policy
            </Typography>
            <Typography variant="body1" gutterBottom>
              FlashLearn may update this privacy policy from time to time to
              reflect changes in our data practices or applicable law. We
              encourage you to periodically review this policy to stay informed
              about our data practices.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If we make material changes to this policy, we will provide you
              with notice by email or by posting a notice on our website. We may
              also seek your consent to the updated policy, if required by
              applicable law.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your continued use of FlashLearn after the effective date of any
              changes to this policy constitutes your acceptance of those
              changes. If you do not agree with any aspect of the updated
              policy, please stop using FlashLearn and contact us via Contact
              Form.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "120px" }}
            >
              If you have any questions or concerns regarding updates to this
              policy, please use the Contact Form to contact us.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Privacy;
