import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import {
  Box,
  Chip,
  DialogContentText,
  Divider,
  Grid,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  isInputStringImageEncoded,
  retrieveImageFromStorage
} from "../../utils/StorageUtil";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

const CARD_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "300px"
};
export default function BasicFlipCard({
  question,
  answer,
  onClickNext,
  onClickEasy,
  onClickMedium,
  onClickHard,
  onClickDelete
}) {
  const [flip, setFlip] = useState(false);
  const [flipNextActionTimeoutId, setFlipNextActionTimeoutId] =
    useState(undefined);
  const [questionImg, setQuestionImg] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const theme = useTheme();
  const isMatchSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (isInputStringImageEncoded(question)) {
      retrieveImageFromStorage(question)
        .then((url) => {
          setQuestionImg(url);
        })
        .catch((e) => {
          console.log(e);
          alert("Failed to retrieve image from storage");
        });
    }
  }, [question]);

  function handleDeleteClick() {
    setAnchorEl(null);
    onClickDelete();
    setConfirmOpen(false);
  }

  function showSuperMemoGradeButtons() {
    const visibility = flip ? "show" : "hidden";

    return (
      <Box sx={{ m: 2, visibility: visibility }}>
        <Divider variant="small" />
        {flip ? (
          <CardActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "58px"
            }}
          >
            <Typography
              sx={{
                color: "#5d6477",
                fontWeight: "bold",
                fontSize: "15px"
              }}
            >
              How hard do you think?
            </Typography>
          </CardActions>
        ) : null}

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop="5px"
        >
          {/*<Grid item xs={0} style={{ paddingRight: "3px" }}>*/}
          {/*  <Chip*/}
          {/*    clickable*/}
          {/*    label="Super easy"*/}
          {/*    onClick={() => {*/}
          {/*      setFlip(false);*/}
          {/*      onClickSuperEasy();*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Grid>*/}

          <Grid item xs={0}>
            <Chip
              clickable
              // color="success"
              style={{
                backgroundColor: "#00bf63",
                color: "#f3f8fc",
                fontWeight: "bold"
              }}
              label="Easy"
              onClick={() => {
                flipCardTpFrontThenDoAction(() => {
                  onClickEasy();
                });
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#7bd5a6";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#00bf63";
              }}
            />
          </Grid>

          <Grid item xs={0} style={{ paddingLeft: "10px" }}>
            <Chip
              clickable
              label="Medium"
              // color="info"
              style={{
                backgroundColor: "#f7bb3f",
                color: "#f3f8fc",
                fontWeight: "bold"
              }}
              onClick={() => {
                flipCardTpFrontThenDoAction(() => {
                  onClickMedium();
                });
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#fbd972";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#f7bb3f";
              }}
            />
          </Grid>

          <Grid item xs={0} style={{ paddingLeft: "10px" }}>
            <Chip
              clickable
              style={{
                backgroundColor: "#ff6565",
                color: "#f3f8fc",
                fontWeight: "bold"
              }}
              // color="error"
              label="Hard"
              onClick={() => {
                flipCardTpFrontThenDoAction(() => {
                  onClickHard();
                });
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#ffb3b3";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#ff6565";
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  function flipCardTpFrontThenDoAction(action) {
    if (!flip) {
      // Not flip, just do action
      action();
      return;
    }
    setFlip(false);
    if (flipNextActionTimeoutId) {
      clearTimeout(flipNextActionTimeoutId);
    }
    const id = setTimeout(() => {
      action();
      setFlipNextActionTimeoutId(undefined);
    }, 500);
    setFlipNextActionTimeoutId(id);
  }

  function handleKeyDown(event) {
    // console.log("Key down: " + event.key);
    if (event.key === "ArrowRight") {
      flipCardTpFrontThenDoAction(onClickNext);
    } else if (event.key === " ") {
      setFlip(!flip);
    } else if (flip) {
      switch (event.key) {
        case "1":
          flipCardTpFrontThenDoAction(onClickEasy);
          break;
        case "2":
          flipCardTpFrontThenDoAction(onClickMedium);
          break;
        case "3":
          flipCardTpFrontThenDoAction(onClickHard);
          break;
        default:
          break;
      }
    }
  }

  function renderQuestion(q) {
    if (isInputStringImageEncoded(q)) {
      return (
        <CardContent style={CARD_STYLE} onClick={() => setFlip(!flip)}>
          <img src={questionImg} alt="Question" />
        </CardContent>
      );
    }

    return (
      <CardContent style={CARD_STYLE} onClick={() => setFlip(!flip)}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h4" component="div">
            {q}
          </Typography>
        </div>
      </CardContent>
    );
  }

  function renderAnswer(answer) {
    return (
      <CardContent style={CARD_STYLE} onClick={() => setFlip(!flip)}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h4" component="div">
            {answer}
          </Typography>
        </div>
      </CardContent>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        tabIndex={0}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "10px"
        }}
        onKeyDown={handleKeyDown}
      >
        <Card
          sx={{
            position: "relative",
            width: isMatchSmallScreen ? "85%" : "45%",
            backgroundColor: "#deebf8",
            marginTop: "8px",
            "@media (max-width: 599px)": {
              marginTop: "5px"
            }
          }}
        >
          <ReactCardFlip isFlipped={flip} flipDirection={"horizontal"}>
            {renderQuestion(question)}
            <>{renderAnswer(answer)}</>
          </ReactCardFlip>

          {/* <MenuItem onClick={handleConfirmOpen}>
            <Delete style={{ marginRight: "0.5em" }} />
            Delete
          </MenuItem>*/}

          <Dialog open={confirmOpen} onClose={handleConfirmClose}>
            <DialogTitle style={{ background: "#deebf8" }}>
              Confirm Delete
            </DialogTitle>
            <DialogContent style={{ background: "#deebf8" }}>
              <DialogContentText>
                Are you sure you want to delete this card? This action cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ background: "#deebf8" }}>
              <Button onClick={handleConfirmClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteClick} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          {showSuperMemoGradeButtons()}

          {flip ? null : (
            <CardActions style={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{
                  width: "50%",
                  marginLeft: "auto",
                  color: "#5d6477",
                  fontWeight: "bold",
                  fontSize: "15px",
                  backgroundColor: "#deebf8",
                  margin: "0 10px",
                  borderRadius: "5px",
                  border: "2px solid #b5c5e5",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    border: "2px solid #5d6477",
                    cursor: "pointer"
                  }
                }}
                onClick={() => setFlip(!flip)}
              >
                check Answer
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
    </div>
  );
}
