export const parseTxtOrCsvContent = (data) => {
  const lines = data.split("\n");
  const map = new Map();
  const delimiters = [",", ";", "\t"];
  // Count the number of occurrences of each delimiter character
  const counts = delimiters.map((delimiter) => {
    let count = 0;
    for (const row of lines) {
      count += row.split(delimiter).length - 1;
    }
    return count;
  });
  // Find the index of the delimiter with the most occurrences
  const maxIndex = counts.indexOf(Math.max(...counts));
  // The delimiter is the character at the same index in the delimiters array
  const delimiter = delimiters[maxIndex];
  // console.log("delimiter: " + delimiter)
  lines.forEach((line) => {
    const strs = line.split(delimiter);
    if (strs && strs.length === 2) {
      const question = strs[0].trim();
      const answer = strs[1].trim();
      // console.log("question: " + question + ", answer: " + answer);
      map.set(`${question}-${answer}`, { question, answer });
    }
  });
  const cards = [];
  map.forEach((value) => {
    cards.push(value);
  });
  return cards;
};
