import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText } from "@mui/material";

export default function DeleteConfirmDialog({
  open,
  message,
  handleCloseDeleteConfirmationClick,
  isDeleting
}) {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const CommonDialogStyle = {
    background: "#deebf8",
    color: "#282d35",
    fontSize: "31px",
    fontWeight: "bold",
    textAlign: "left",
    "@media (max-width: 499px)": {
      fontSize: "25px"
    }
  };

  useEffect(() => {
    setDeleteConfirmationOpen(open);
  }, [open]);

  return (
    <Dialog
      open={deleteConfirmationOpen}
      onClose={() => handleCloseDeleteConfirmationClick(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          ...CommonDialogStyle
        }}
        id="alert-dialog-title"
      >
        {"Confirm deletion"}
      </DialogTitle>
      <DialogContent style={{ background: "#deebf8", color: "#282d35" }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            fontSize: "18px",
            "@media (max-width: 499px)": {
              fontSize: "16px",
              textAlign: "left"
            }
          }}
        >
          {isDeleting ? "Deleting..." : message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ background: "#deebf8", color: "#282d35" }}>
        <Button
          disabled={isDeleting}
          onClick={() => handleCloseDeleteConfirmationClick(false)}
          color="primary"
        >
          No
        </Button>
        <Button
          disabled={isDeleting}
          onClick={() => handleCloseDeleteConfirmationClick(true)}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
