import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicDeck from "../dayun-ui-compontents/card/BasicDeck";
import CreateDeckDialog from "../dayun-ui-compontents/dialog/CreateDeckDialog";
import { Grid, Tab, Tabs } from "@mui/material";
import NotItemFound from "../dayun-ui-compontents/NoItemFound";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import ProgressDialog from "../dayun-ui-compontents/dialog/ProgressDialog";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { DeckTabName, DEFAULT_SUBJECT_NAME, RoutePathName } from "../constant";
import {
  addNewDeck,
  deleteExistingDeck,
  getDeckAndCards,
  getDecksAndCards,
  getDecksForUser,
  updateExistingDeck,
  updateShareStatusForDeck
} from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ImportCardDialog from "../dayun-ui-compontents/dialog/ImportCardDialog";
import Typography from "@mui/material/Typography";

function DecksDashboard({ signOut, user }) {
  const navigate = useNavigate();
  const [selectedTab, _] = useState(DeckTabName.MY_DECK_NAME);
  const [decks, setDecks] = useState(undefined);
  const [deckIdToLearningStats, setDeckIdToLearningStats] = useState(new Map());
  const [deckIdToCards, setDeckIdToCards] = useState(new Map());
  const [createDeckDialogOpen, setCreateDeckDialogOpen] = useState(false);
  const [editedDeck, setEditedDeck] = useState(undefined);
  const [importCardDialogOpen, setImportCardDialogOpen] = useState(false);
  const [loadingDecks, setLoadingDecks] = useState(false);
  const [categories, setCategories] = useState(undefined);

  function groupAndSetCategories(decks) {
    const fetchedSubjectToDecks = {};
    for (const deck of decks) {
      const subject = deck.subject || DEFAULT_SUBJECT_NAME;
      if (fetchedSubjectToDecks[subject] === undefined) {
        fetchedSubjectToDecks[subject] = {
          name: subject,
          decks: []
        };
      }
      fetchedSubjectToDecks[subject].decks.push(deck);
    }
    // flatten the object to an array
    const categories = [];
    for (const subject in fetchedSubjectToDecks) {
      categories.push(fetchedSubjectToDecks[subject]);
    }
    setCategories(categories);
  }

  useEffect(() => {
    if (user === undefined || user === null) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setLoadingDecks(true);
    }, 500);

    getDecksForUser(user)
      .then((data) => {
        setDecks(data.decks);
      })
      .catch((error) => {
        alertForApiError(error);
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoadingDecks(false);
      });
  }, [user]);

  useEffect(() => {
    if (decks === undefined) {
      return;
    }
    const deckIds = decks.map((deck) => deck.id);
    getDecksAndCards(user, deckIds).then((data) => {
      const results = data.map((deckAndCards) => {
        return [deckAndCards.deck.id, deckAndCards.cards];
      });
      setDeckIdToCards(new Map(results));
    });
    setDeckIdToLearningStats(new Map());
    groupAndSetCategories(decks);
  }, [decks]);

  function handleCreateDeck(props) {
    console.log("handleCreateDeck");
    setCreateDeckDialogOpen(false);
    setLoadingDecks(true);
    addNewDeck(props, user, decks.length)
      .then((deck) => {
        console.log("Deck added", deck);
        setDecks([deck, ...decks]);
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setLoadingDecks(false);
      });
  }

  async function handleDeleteDeck(deck) {
    deleteExistingDeck(user, deck)
      .then(() => {
        console.log("Deck deleted", deck);
        setDecks(decks.filter((d) => d.id !== deck.id));
      })
      .catch((e) => {
        alertForApiError(e);
      });
  }

  function handleShareDeck(deck) {
    updateShareStatusForDeck(user, deck, { isShared: true })
      .then((updatedDeck) => {
        setDecks(
          decks.map((d) => {
            if (d.id === updatedDeck.id) {
              return { ...d, ...updatedDeck };
            }
            return d;
          })
        );
      })
      .catch((e) => {
        alertForApiError(e);
      });
  }

  function handleEditDeck(deck, props) {
    updateExistingDeck(user.attributes.sub, deck, props)
      .then((updatedDeck) => {
        console.log("Deck updated", updatedDeck);
        setDecks(
          decks.map((d) => {
            if (d.id === updatedDeck.id) {
              return { ...d, ...updatedDeck };
            }
            return d;
          })
        );
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setEditedDeck(undefined);
      });
  }

  function getDeckButtonsUi() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          paddingRight: "18px",
          paddingTop: "20px"
        }}
      >
        <Fab
          variant="extended"
          onClick={() => setCreateDeckDialogOpen(true)}
          sx={{
            color: "#282d35",
            fontWeight: "bold",
            fontSize: "22px",
            right: "160px",
            marginTop: "20px",
            backgroundColor: "#deebf8",
            position: "relative",
            transition: "background-color 0.2s ease-in-out",
            "&:hover::before": {
              content: '"Add Deck"',
              position: "absolute",
              top: "-29px",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "3px",
              fontSize: "14px",
              backgroundColor: "#282d35",
              color: "#deebf8",
              borderRadius: "4px"
            },
            "@media (max-width: 630px)": {
              justifyContent: "center",
              position: "absolute",
              right: "110px",
              marginTop: "0px",
              marginLeft: "10px",
              fontSize: "14px",
              "&:hover::before": {
                top: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "3px",
                fontSize: "12px",
                backgroundColor: "#282d35",
                color: "#deebf8",
                borderRadius: "4px"
              },
              "@media (max-width: 629px)": {
                display: "none"
              }
            }
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#b5c5e5";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#deebf8";
          }}
        >
          <AddIcon
            sx={{
              mr: 1,
              ml: 1,
              "@media (max-width: 700px)": {
                mr: 0,
                ml: 0
              },
              "@media (max-width: 420px)": {
                width: 17,
                height: 17,
                fontSize: 5
              }
            }}
          />
        </Fab>
        <Fab
          variant="extended"
          onClick={() => setImportCardDialogOpen(true)}
          sx={{
            color: "#282d35",
            fontWeight: "bold",
            backgroundColor: "#deebf8",
            marginTop: "20px",
            position: "absolute",
            right: "87px",
            fontSize: "17.5px",
            transition: "background-color 0.2s ease-in-out",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover::before": {
              content: '"Import Deck"',
              position: "absolute",
              top: "-29px",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "3px",
              fontSize: "14px",
              backgroundColor: "#282d35",
              color: "#deebf8",
              borderRadius: "4px"
            },
            "@media (max-width: 630px)": {
              alignContent: "center",
              position: "absolute",
              right: "15px",
              marginTop: "0px",
              marginLeft: "10px",
              fontSize: "14px",
              "&:hover::before": {
                top: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "3px",
                fontSize: "12px",
                backgroundColor: "#282d35",
                color: "#deebf8",
                borderRadius: "4px"
              },
              "@media (max-width: 629px)": {
                display: "none"
              }
            }
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#b5c5e5";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#deebf8";
          }}
        >
          <SystemUpdateAltIcon
            sx={{
              mr: 1,
              ml: 1,
              "@media (max-width: 700px)": {
                mr: 0,
                ml: 0
              },
              "@media (max-width: 420px)": {
                width: 17,
                height: 17,
                fontSize: 5
              }
            }}
          />
        </Fab>
      </Box>
    );
  }
  function getDecks() {
    if (decks === undefined) {
      return null;
    }
    if (decks.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh"
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            align="center"
            sx={{
              color: "#deebf8",
              textAlign: "center",
              fontSize: "27px",
              "@media (max-width: 630px)": {
                fontSize: "18px"
              }
            }}
          >
            No deck found
          </Typography>
        </div>
      );
    }
    return (
      <Grid item xs={12}>
        {categories &&
          categories.map((category, index) => (
            <Grid container spacing={1} key={index}>
              <Grid item xs={12} marginLeft={5} marginTop={6}>
                <Typography
                  variant="h4"
                  component="h4"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#deebf8",
                    "@media (max-width: 630px)": {
                      fontSize: "17px"
                    }
                  }}
                >
                  {category.name}
                </Typography>
              </Grid>
              {category.decks.map((deck, index) => (
                <Grid
                  container
                  justifyContent="left"
                  alignItems="left"
                  item
                  xs={12}
                  md={4}
                  sm={4}
                  lg={2}
                  xl={2}
                  marginLeft={3.71}
                  padding={{ xs: 1, md: 2 }}
                  key={deck.id}
                  marginTop={1}
                  sx={{
                    "@media (max-width: 700px)": {
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridGap: "10px",
                      padding: "40px",
                      marginRight: "24px"
                    }
                  }}
                >
                  <BasicDeck
                    user={user}
                    deck={deck}
                    cards={deckIdToCards.get(deck.id)}
                    onClickDeck={() => {}}
                    onClickDelete={() => handleDeleteDeck(deck)}
                    onClickEdit={() => setEditedDeck(deck)}
                    onClickShare={() => handleShareDeck(deck)}
                    todayLearningStats={deckIdToLearningStats.get(deck.id)}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
      </Grid>
    );
  }

  const goToOtherPageWhenClickingTab = (value) => {
    if (value !== DeckTabName.MY_DECK_NAME) {
      navigate(RoutePathName.SHARE_DECKS);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        paddingLeft: "60px",
        margin: "0 auto",
        "@media (max-width: 1281px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => goToOtherPageWhenClickingTab(newValue)}
        sx={{
          "& .MuiTabs-indicator": { backgroundColor: "#5271ff" },
          "& .MuiTab-root": { color: "#deebf8" },
          "& .Mui-selected": { color: "#5ce1e6" }
        }}
      >
        <Tab
          label="My Decks"
          value="My Decks"
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "24px",
            marginLeft: "23px",
            marginTop: "28px",
            "@media (max-width: 630px)": {
              fontSize: "16.5px",
              width: "38%",
              height: "40px",
              marginTop: "10px",
              justifyContent: "center",
              marginLeft: "10px"
            }
          }}
        />
        <Tab
          label="Shared Decks"
          value="Shared Decks"
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "23px",
            marginLeft: "20px",
            marginTop: "28px",
            "@media (max-width: 630px)": {
              fontSize: "16.5px",
              width: "46%",
              height: "40px",
              marginTop: "10px",
              justifyContent: "center",
              position: "absolute",
              left: "140px"
            }
          }}
        />
        {getDeckButtonsUi()}

        <CreateDeckDialog
          user={user}
          onCreateDeck={(props) => handleCreateDeck(props)}
          onCloseDialog={() => setCreateDeckDialogOpen(false)}
          open={createDeckDialogOpen}
        />
        <CreateDeckDialog
          user={user}
          currentDeck={editedDeck}
          onCreateDeck={(props) => handleEditDeck(editedDeck, props)}
          onCloseDialog={() => setEditedDeck(undefined)}
          open={editedDeck !== undefined}
        />
        <ImportCardDialog
          onCloseDialog={() => {
            setImportCardDialogOpen(false);
          }}
          open={importCardDialogOpen}
          currentDeckSize={decks ? decks.length : 0}
          user={user}
          onDeckImported={(deck, cards) => {
            setDecks([deck, ...decks]);
            setDeckIdToCards(new Map(deckIdToCards.set(deck.id, cards)));
          }}
        />
        <ProgressDialog
          open={decks === undefined && loadingDecks}
          title={"Loading..."}
        />
      </Tabs>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& > :not(style)": { m: 1 }
          }}
        >
          <Fab
            variant="extended"
            onClick={() => setCreateDeckDialogOpen(true)}
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              fontSize: "17.5px",
              backgroundColor: "#deebf8",
              position: "absolute",
              right: "120px",
              transition: "background-color 0.2s ease-in-out",
              "@media (min-width: 630px)": {
                display: "none"
              },
              "@media (max-width: 629px)": {
                visibility: "visible",
                fontSize: "14px",
                height: "30px",
                justifyContent: "center",
                position: "fixed",
                bottom: 60,
                right: 11,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <AddIcon sx={{ m: 1, "@media (max-width: 629px)": { m: 0.1 } }} />
          </Fab>
          <Fab
            variant="extended"
            onClick={() => setImportCardDialogOpen(true)}
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              fontSize: "17.5px",
              backgroundColor: "#deebf8",
              position: "absolute",
              right: "28px",
              transition: "background-color 0.2s ease-in-out",
              "@media (min-width: 630px)": {
                display: "none"
              },
              "@media (max-width: 629px)": {
                visibility: "visible",
                fontSize: "14px",
                height: "30px",
                justifyContent: "center",
                position: "fixed",
                bottom: 20,
                right: 11,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <SystemUpdateAltIcon
              sx={{ m: 1, "@media (max-width: 629px)": { m: 0.1 } }}
            />
          </Fab>
        </Box>
        {getDecks()}
      </>
    </Box>
  );
}

export default withDayunAuthenticator(DecksDashboard);
