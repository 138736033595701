// export const SUPPORTED_SOCIAL_PROVIDERS = ["google"];
export const SUPPORTED_SOCIAL_PROVIDERS = [];

export const APP_NAME = "FlashLearn";

export const DEFAULT_SUBJECT_NAME = "Default";

export const CUSTOM_PROFILE_ATTRIBUTE = "custom:profilePicture";

export const IMPORT_SOURCE_TXT = "Anki";
export const IMPORT_SOURCE_SPREADSHEET = "Spreadsheet";

export const DEFAULT_CARDS_TO_LEARN_PER_ROUND = 10;

export const DifficultyLevels = {
  Easy: 2,
  Medium: 1,
  Hard: 0
};

/**
 * A limitation of this app
 */
export class Limitation {
  static MAX_DECKS_PER_USER = 20;

  static MAX_CARDS_PER_DECK = 100;

  static MAX_LEN_QUESTION = 100;

  static MAX_LEN_ANSWER = 200;

  static MAX_LEN_DECK_NAME = 25;
  static MAX_LEN_DECK_DESCRIPTION = 100;

  static MAX_LEN_DECK_SUBJECT = 15;
}

export class DeckTabName {
  static MY_DECK_NAME = "My Decks";
  static PUBLIC_DECK_NAME = "Public Decks";
  static SHARE_DECK_NAME = "Shared Decks";
}

export class RoutePathName {
  static HOME = "/";
  static CONTACT = "/contact";
  static PROFILE = "/profile";
  static SEARCH = "/search";
  static DECK = "/deck";
  static LEARN = "/learn";
  static DECKS = "/decks";
  static PUBLIC = "/public";
  static ACHIEVEMENT = "/achievement";
  static SHARE_DECKS = "/shareDecks";
  static PRIVACY = "/privacy";
  static TEST_PAGE = "/test";
}

export class SuperMemoGrade {
  static PERFECT_RESPONSE = 5;
  static CORRECT_RESPONSE_AFTER_HESITATION = 4;
  static CORRECT_RESPONSE_RECALLED_WITH_SERIOUS_DIFFICULTY = 3;
  static INCORRECT_RESPONSE_WHERE_THE_CORRECT_ONE_SEEMED_EASY_TO_RECALL = 2;
  // 1 or 0 doesn't make sense. Same as 2
  static INCORRECT_RESPONSE_THE_CORRECT_ONE_REMEMBERED = 1;
  static COMPLETE_BLACKOUT = 0;
}

export class DeckOwnerType {
  static OWNER = "owner";
  static SHARED_BY_OTHERS = "sharedByOthers";
  static ALL = "all";
}
