import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Tab,
  Tabs,
  Typography
} from "@mui/material/";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DifficultyLevels, RoutePathName } from "../../constant";
import { useNavigate } from "react-router-dom";
import { getDifficultFromDeckSuperMemo } from "../../utils/DeckUtil";
import {calculatePercentage} from "../../utils/AchievementUtil";



const TAB_PER_ROUND = 0;
const TAB_PER_DECK = 1;
export function LearningProgressPopUpDialog({
  open,
  onClickNextRound,
  onClose,
  allCardsInDeck,
  cardsToDifficultyThisRound,
  deck
}) {
  const [tabValue, setTabValue] = useState(TAB_PER_ROUND);
  const [scorePercentage, setScorePercentage] = useState(0);
  const [stats, setStats] = useState({});
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (
      allCardsInDeck === undefined ||
      cardsToDifficultyThisRound === undefined
    ) {
      return;
    }
    let cardSizeToCalculatePercentage = 0;
    let difficultLevels = [];
    if (tabValue === TAB_PER_ROUND) {
      difficultLevels = Array.from(cardsToDifficultyThisRound.values());
      cardSizeToCalculatePercentage = difficultLevels.length;
    } else if (tabValue === TAB_PER_DECK) {
      difficultLevels = getDifficultFromDeckSuperMemo(
        allCardsInDeck,
        cardsToDifficultyThisRound
      );
      cardSizeToCalculatePercentage = allCardsInDeck.length;
    } else {
      throw new Error("Invalid tab value: " + tabValue);
    }

    const percentage = calculatePercentage(
      difficultLevels,
      cardSizeToCalculatePercentage
    );
    setScorePercentage(percentage);

    const easyCount = difficultLevels.filter(
      (l) => l === DifficultyLevels.Easy
    ).length;
    const mediumCount = difficultLevels.filter(
      (l) => l === DifficultyLevels.Medium
    ).length;
    const hardCount = difficultLevels.filter(
      (l) => l === DifficultyLevels.Hard
    ).length;

    const totalDifficultyCount = easyCount + mediumCount + +hardCount;
    setStats({
      easyCount,
      mediumCount,
      hardCount,
      totalDifficultyCount
    });
  }, [allCardsInDeck, cardsToDifficultyThisRound, tabValue]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#5271ff"
      },
      secondary: {
        main: "#f7bb3f"
      },
      third: {
        main: "#ff6565"
      },
      forth: {
        main: "#00bf63"
      }
    }
  });
  const secColor = "#d1d1d1",
    thickness = 4,
    size = 175;

  const progressSx = {
    marginTop: "20px",
    marginLeft: "20px",
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 ${(thickness / 44) * size}px ${secColor}`
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose}>
        <Container style={{ background: "#282d35", color: "#deebf8" }}>
          <DialogTitle style={{ fontWeight: "bold", textAlign: "center" }}>
            Round Completed!
          </DialogTitle>
          <DialogTitle style={{ fontWeight: "bold", textAlign: "center" }}>
            {deck && deck.name}
          </DialogTitle>
          <DialogContent>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": { backgroundColor: "#5ce1e6" },
                "& .MuiTab-root": { color: "#deebf8" },
                "& .Mui-selected": { color: "#5ce1e6" }
              }}
            >
              <Tab label="This Round" sx={{ color: "#deebf8" }} />
              <Tab label="Overall" sx={{ color: "#deebf8" }} />
            </Tabs>
            <div
              style={{
                position: "relative",
                display: "inline-block"
              }}
            >
              <CircularProgress
                variant="determinate"
                value={scorePercentage}
                size={size}
                thickness={thickness}
                sx={progressSx}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center"
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{
                    color: "#deebf8",
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginTop: "15px",
                    marginLeft: "20px"
                  }}
                >
                  {`${Math.round(scorePercentage)}%`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  style={{
                    color: "#deebf8",
                    fontSize: "14px",
                    marginLeft: "20px"
                  }}
                >
                  MASTERY
                </Typography>
              </div>
            </div>
            <DialogContentText
              style={{
                color: "#deebf8",
                fontWeight: "bold",
                marginTop: "3vw",
                marginBottom: "1.3vw"
              }}
            >
              Difficulty Ratings
            </DialogContentText>
            {/*
          <DialogContentText style={{ color: "#deebf8", marginTop: "1vw" }}>
            Easy:{" "}
            {difficultyLevels.filter((l) => l === DifficultyLevels.Easy).length}
          </DialogContentText>
          <DialogContentText style={{ color: "#deebf8", marginTop: "1vw" }}>
            Medium:{" "}
            {
              difficultyLevels.filter((l) => l === DifficultyLevels.Medium)
                .length
            }
          </DialogContentText>
          <DialogContentText style={{ color: "#deebf8", marginTop: "1vw" }}>
            Difficult:{" "}
            {difficultyLevels.filter((l) => l === DifficultyLevels.Hard).length}
          </DialogContentText>{" "}
          */}
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Typography
                variant="body1"
                style={{ color: "#deebf8", flex: 1, width: "40px" }}
              >
                Easy
              </Typography>

              <LinearProgress
                variant="determinate"
                value={(stats.easyCount / stats.totalDifficultyCount) * 100}
                sx={{
                  width: "56%",
                  height: 10,
                  borderRadius: 5,
                  margin: "0 1rem",
                  color: "#5ce1e6",
                  backgroundColor: "#deebf8"
                }}
                color="forth"
              />
              <Typography
                variant="body1"
                style={{ color: "#deebf8", width: "20px" }}
              >
                {stats.easyCount}
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Typography
                variant="body1"
                style={{ color: "#deebf8", flex: 1, width: "40px" }}
              >
                Medium
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(stats.mediumCount / stats.totalDifficultyCount) * 100}
                sx={{
                  width: "56%",
                  height: 10,
                  borderRadius: 5,
                  margin: "0 1rem",
                  color: "#f7bb3f",
                  backgroundColor: "#deebf8"
                }}
                color="secondary"
              />
              <Typography
                variant="body1"
                style={{ color: "#deebf8", width: "20px" }}
              >
                {stats.mediumCount}
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Typography
                variant="body1"
                style={{ color: "#deebf8", flex: 1, width: "40px" }}
              >
                Hard
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(stats.hardCount / stats.totalDifficultyCount) * 100}
                sx={{
                  width: "56%",
                  height: 10,
                  borderRadius: 5,
                  margin: "0 1rem",
                  color: "#ff6565",
                  backgroundColor: "#deebf8"
                }}
                color="third"
              />
              <Typography
                variant="body1"
                style={{ color: "#deebf8", width: "20px" }}
              >
                {stats.hardCount}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                onClick={() => {
                  navigate(RoutePathName.DECKS);
                }}
                color="primary"
                sx={{
                  backgroundColor: "#5ce1e6",
                  color: "#282d35",
                  fontFamily: "Yantramanav, sans-serif",
                  fontSize: "16px",
                  marginTop: "15px",
                  marginRight: "15px",
                  marginBottom: "30px",
                  transition: "background-color 0.2s ease-in-out",
                  "@media (max-width: 499px)": {
                    fontSize: "13px"
                  }
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#a9edef";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#5ce1e6";
                }}
              >
                {"\u003C"} Dashboard
              </Button>
              <Button
                onClick={onClickNextRound}
                color="primary"
                sx={{
                  backgroundColor: "#5ce1e6",
                  color: "#282d35",
                  fontFamily: "Yantramanav, sans-serif",
                  fontSize: "16px",
                  marginTop: "15px",
                  marginBottom: "30px",
                  transition: "background-color 0.2s ease-in-out",
                  "@media (max-width: 499px)": {
                    fontSize: "13px",
                    marginRight: "20px"
                  }
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#a9edef";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#5ce1e6";
                }}
              >
                Next Round {"\u003E"}
              </Button>
            </div>
          </DialogActions>
        </Container>
      </Dialog>
    </ThemeProvider>
  );
}
