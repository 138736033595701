import { SuperMemoGrade } from "../constant";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BasicFlipCard from "../dayun-ui-compontents/card/BasicFlipCard";
import CreateCardDialog from "../dayun-ui-compontents/dialog/CreateCardDialog";
import NoItemFound from "../dayun-ui-compontents/NoItemFound";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Profile.css";
import Typography from "@mui/material/Typography";
import {
  addMissingLearningCards,
  getDeckAndCards,
  updateExistingCard,
  updateExistingCardSpacedRepetition
} from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";
import {
  filterCardPerRound,
  superMemoToDayunDifficultLevel
} from "../utils/CardUtil";
import { LearningProgressPopUpDialog } from "../dayun-ui-compontents/dialog/LearningProgressPopUpDialog";
import { supermemo } from "../utils/supermemo";
import ProgressDialog from "../dayun-ui-compontents/dialog/ProgressDialog";
import { CardList } from "../dayun-ui-compontents/card/CardList";
import { canDeckBeEditedByTheUser } from "../utils/ValidationUtil";

function LearningPage({ signOut, user }) {
  const { id } = useParams();

  const [deck, setDeck] = useState(undefined);
  const [cards, setCards] = useState(undefined);
  const [pickedCards, setPickedCards] = useState(undefined);

  const [currentCard, setCurrentCard] = useState(undefined);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [editCardDialogOpen, setEditCardDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [
    studyScorePercentagePopupDialogOpen,
    setStudyScorePercentagePopupDialogOpen
  ] = useState(false);
  const [cardIdToDifficulty, setCardIdToDifficulty] = useState(new Map());

  const handleGoBack = () => {
    window.history.back();
  };

  const handleStudyScorePercentagePopupDialogClose = () => {
    setStudyScorePercentagePopupDialogOpen(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 800);

    getDeckAndCards(user, id)
      .then((r) => {
        const deck = r.deck;
        if (canDeckBeEditedByTheUser(deck, user)) {
          setCanEdit(true);
        }
        setDeck(deck);

        const initAllLearningCards = async (fetchedCards) => {
          // Add missing learning cards for the case shared by others and there is a bug in the backend
          const addedLearningCards = await addMissingLearningCards(
            deck.id,
            user.attributes.sub,
            fetchedCards
          );
          if (addedLearningCards && addedLearningCards.length > 0) {
            console.log(
              "Trying to add missing learning cards for: ",
              fetchedCards
            );
            fetchedCards = fetchedCards.map((fetchedCard) => {
              const addedLearningCard = addedLearningCards.find(
                (addedLearningCard) => {
                  return addedLearningCard.cardId === fetchedCard.id;
                }
              );
              if (addedLearningCard) {
                return { ...addedLearningCard, ...fetchedCard };
              }
              return fetchedCard;
            });
            console.log("addMissingLearningCards done: ", fetchedCards);
          }

          const cardWithIndex = fetchedCards.map((card, index) => {
            return { ...card, index };
          });
          setCards(cardWithIndex);
          const cardsThisRound = filterCardPerRound(
            cardWithIndex,
            r.deck.numCardsPerRound
          );
          setPickedCards(cardsThisRound);
        };
        initAllLearningCards(r.cards);
      })
      .catch((error) => {
        alertForApiError(error);
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      pickedCards !== undefined &&
      pickedCards.length > 0 &&
      currentIndex < pickedCards.length
    ) {
      setCurrentCard(pickedCards[currentIndex]);
    } else {
      setCurrentCard(undefined);
    }
  }, [currentIndex, pickedCards]);

  function handleEditCard(card, props) {
    console.log("handleEditCard", card, props);
    updateExistingCard(user.attributes.sub, deck.id, card, props)
      .then((updatedCard) => {
        setCards(
          cards.map((c) => {
            if (c.id === updatedCard.id) {
              return { ...c, ...updatedCard };
            }
            return c;
          })
        );
        setPickedCards(
          pickedCards.map((c) => {
            if (c.id === updatedCard.id) {
              return { ...c, ...updatedCard };
            }
            return c;
          })
        );
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setEditCardDialogOpen(false);
      });
  }

  const handleNextClick = () => {
    if (currentIndex >= cards.length - 1) {
      return;
    }
    setCurrentIndex(currentIndex + 1);
  };
  const handlePrevClick = () => {
    if (currentIndex === 0) {
      return;
    }
    setCurrentIndex(currentIndex - 1);
  };

  const handleClickSuperMemoBtn = (superMemoGrade) => {
    console.log("handleClickSuperMemoBtn, superMemoGrade:", superMemoGrade);
    const { efactor, interval, repetition } = supermemo(
      {
        efactor: currentCard.efactor,
        interval: currentCard.interval,
        repetition: currentCard.repetition
      },
      superMemoGrade
    );
    currentCard.efactor = efactor;
    currentCard.interval = interval;
    currentCard.repetition = repetition;

    setPickedCards(
      pickedCards.map((c) => {
        if (c.id === currentCard.id) {
          return { ...c, ...currentCard };
        }
        return c;
      })
    );
    setCards(
      cards.map((c) => {
        if (c.id === currentCard.id) {
          return { ...c, ...currentCard };
        }
        return c;
      })
    );
    // Update the difficulty level
    cardIdToDifficulty.set(
      currentCard.id,
      superMemoToDayunDifficultLevel(superMemoGrade)
    );
    setCardIdToDifficulty(new Map([...cardIdToDifficulty]));

    // Find the next card to study
    for (let i = 0; i < pickedCards.length; i++) {
      const pickedCardId = pickedCards[i].id;
      if (pickedCardId === currentCard.id) {
        continue;
      }
      if (cardIdToDifficulty.has(pickedCardId)) {
        continue;
      }
      setCurrentIndex(i);
    }

    // Update the card in the database asynchronously
    updateExistingCardSpacedRepetition(
      user.attributes.sub,
      deck,
      currentCard,
      efactor,
      interval,
      repetition,
      superMemoGrade
    );

    // Complete all picked cards
    if (cardIdToDifficulty.size === pickedCards.length) {
      setStudyScorePercentagePopupDialogOpen(true);
    }
  };

  function renderPickedCardIndex() {
    if (pickedCards === undefined || pickedCards.length === 0) {
      return;
    }
    const pickedCardInd = pickedCards[currentIndex].index + 1;
    const cardMsg = cards.length === 1 ? "card" : "cards";
    return (
      <>
        {" "}
        {cardMsg}: {pickedCardInd}/{cards.length}
      </>
    );
  }

  function getButtonsUi(deck) {
    if (deck === undefined) {
      if (loading) {
        return <ProgressDialog open={loading} title={"Loading..."} />;
      } else {
        return <></>;
      }
    }
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "24px",
            "& > :not(style)": { m: 1 }
          }}
        >
          <Fab
            onClick={handleGoBack}
            variant="extended"
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              backgroundColor: "#deebf8",
              position: "absolute",
              left: "30px",
              marginTop: "24px",
              fontSize: "17.5px",
              transition: "background-color 0.2s ease-in-out",
              "@media (max-width: 499px)": {
                fontSize: "12px",
                width: "10%",
                height: "40px",
                marginTop: "14px",
                justifyContent: "center",
                position: "absolute",
                left: "22px"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <ArrowBackIcon sx={{ mr: 0.5 }} />
          </Fab>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div
            tabIndex={0}
            style={{
              width: "85%",
              maxWidth: 900,
              marginTop: 90,
              marginBottom: 10,
              backgroundColor: "#282d35",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            <Typography
              variant="h4"
              align="left"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#deebf8",
                fontSize: "26px",
                marginTop: "35px",
                "@media (max-width: 499px)": {
                  fontSize: "16px",
                  marginTop: "2px",
                  textAlign: "left"
                }
              }}
            >
              Deck: {deck && deck.name}
            </Typography>
            <Typography
              variant="h4"
              align="right"
              gutterBottom
              sx={{
                color: "#deebf8",
                fontSize: "20px",
                marginTop: "35px",
                "@media (max-width: 499px)": {
                  fontSize: "12px",
                  marginTop: "2px",
                  textAlign: "right"
                }
              }}
            >
              {renderPickedCardIndex()}
            </Typography>
          </div>
        </Box>
      </>
    );
  }

  return (
    <div>
      {getButtonsUi(deck)}

      <CreateCardDialog
        user={user}
        currentCard={currentCard}
        onCreateCard={(props) => handleEditCard(currentCard, props)}
        onCloseDialog={() => setEditCardDialogOpen(false)}
        open={editCardDialogOpen && canEdit}
      />

      <LearningProgressPopUpDialog
        deck={deck}
        onClickNextRound={() => {
          window.location.reload();
        }}
        open={studyScorePercentagePopupDialogOpen}
        allCardsInDeck={cards}
        cardsToDifficultyThisRound={cardIdToDifficulty}
        onClose={handleStudyScorePercentagePopupDialogClose}
      />

      {currentCard ? (
        <>
          <BasicFlipCard
            question={currentCard.question}
            answer={currentCard.answer}
            onClickNext={handleNextClick}
            onClickPrev={handlePrevClick}
            onClickEdit={() => setEditCardDialogOpen(true)}
            onClickSuperEasy={() =>
              handleClickSuperMemoBtn(SuperMemoGrade.PERFECT_RESPONSE)
            }
            onClickEasy={() =>
              handleClickSuperMemoBtn(
                SuperMemoGrade.CORRECT_RESPONSE_AFTER_HESITATION
              )
            }
            onClickMedium={() =>
              handleClickSuperMemoBtn(
                SuperMemoGrade.CORRECT_RESPONSE_RECALLED_WITH_SERIOUS_DIFFICULTY
              )
            }
            onClickHard={() =>
              handleClickSuperMemoBtn(
                SuperMemoGrade.INCORRECT_RESPONSE_WHERE_THE_CORRECT_ONE_SEEMED_EASY_TO_RECALL
              )
            }
            onClickSuperHard={() =>
              handleClickSuperMemoBtn(
                SuperMemoGrade.INCORRECT_RESPONSE_THE_CORRECT_ONE_REMEMBERED
              )
            }
            onClickCompleteBlack={() =>
              handleClickSuperMemoBtn(SuperMemoGrade.COMPLETE_BLACKOUT)
            }
          />
          <CardList cards={cards} />
        </>
      ) : null}
      {cards !== undefined && cards.length === 0 ? (
        <NoItemFound></NoItemFound>
      ) : null}
    </div>
  );
}

export default withDayunAuthenticator(LearningPage);
