import { Storage } from "aws-amplify";
import { CUSTOM_PROFILE_ATTRIBUTE } from "../constant";

export const getStorageKeyForCardImage = (userId, dateObj, fileName) => {
  return `${userId}/${dateObj.toISOString()}/${fileName}`;
};

const IMAGE_DATA_PREFIX = "data:image:";

export const encodeImageKeyInFlashcard = (storageKey) => {
  return `${IMAGE_DATA_PREFIX}${storageKey}`;
};

const decodeImageKeyInFlashcard = (inputStr) => {
  return inputStr.replace(IMAGE_DATA_PREFIX, "");
};

export const retrieveImageFromStorage = (inputStrWithPrefix) => {
  if (!isInputStringImageEncoded(inputStrWithPrefix)) {
    throw new Error("Input string is not encoded");
  }
  const key = decodeImageKeyInFlashcard(inputStrWithPrefix);
  return Storage.get(key, { level: "private" });
};

export const saveImageToStorageForCard = (userId, file) => {
  return resizeImage(file).then((resizedFile) => {
    const fileName = file.name;
    const storageKey = getStorageKeyForCardImage(userId, new Date(), fileName);
    const contentType = file.type;
    const uploadFilePromise = Storage.put(storageKey, resizedFile, {
      contentType: contentType,
      level: "private"
    });
    return uploadFilePromise;
  });
};

export const isInputStringImageEncoded = (inputStr) => {
  if (inputStr === undefined || inputStr === null) {
    return false;
  }
  if (typeof inputStr !== "string") {
    return false;
  }
  return inputStr.startsWith(IMAGE_DATA_PREFIX);
};

export const resizeImage = (image, maxWidth = 350, maxHeight = 350) => {
  return new Promise((resolve, reject) => {
    const imageUrl = URL.createObjectURL(image);
    const imageElement = new Image();
    imageElement.src = imageUrl;

    imageElement.onload = () => {
      const canvas = document.createElement("canvas");
      let width = imageElement.width;
      let height = imageElement.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(imageElement, 0, 0, width, height);
      canvas.toBlob((blob) => {
        resolve(new File([blob], image.name, { type: image.type }));
      }, "image/jpeg");
    };
  });
};

export const uploadProfilePicture = async (file) => {
  return resizeImage(file, 100, 100).then((resizedFile) => {
    return Storage.put(`profilePicture`, file, {
      contentType: file.type,
      level: "private"
    });
  });
};

export const getUserProfilePicture = async (user) => {
  // Use user picture first if it provides, not fall back to user.picture from cognito like Google picture
  let profilePictureS3Link = user.attributes.picture;
  if (CUSTOM_PROFILE_ATTRIBUTE in user.attributes) {
    const customProfilePictureS3key = user.attributes[CUSTOM_PROFILE_ATTRIBUTE];
    profilePictureS3Link = await Storage.get(customProfilePictureS3key, {
      level: "private"
    });
  }
  return profilePictureS3Link;
};

export const uploadTempFile = async (file) => {
  const fileName = `temp/${file.name}`;
  return Storage.put(fileName, file, {
    contentType: file.type,
    level: "private",
    metadata: {
      "x-amz-storage-class": "STANDARD_IA",
      "x-amz-meta-expires": new Date(
        Date.now() + 1000 * 60 * 60 * 1
      ).toUTCString() // 1 hour expiration
    }
  }).then((result) => {
    const url = Storage.get(result.key, { level: "private" });
    return url;
  });
};
