import {DifficultyLevels} from "../constant";

/**
 * @param {*} difficultyLevels a list of difficult count for each card
 * @returns
 */
export function calculatePercentage(difficultyLevels, totalCardSize) {
  const fullScore = totalCardSize * DifficultyLevels.Easy;
  let difficultyScore = 0;
  for (const score of difficultyLevels) {
    difficultyScore += score;
  }
  return Math.round((difficultyScore / fullScore) * 100);
}