import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import CreateCardDialog from "../dayun-ui-compontents/dialog/CreateCardDialog";
import CreateDeckDialog from "../dayun-ui-compontents/dialog/CreateDeckDialog";
import NoItemFound from "../dayun-ui-compontents/NoItemFound";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import {
  encodeImageKeyInFlashcard,
  saveImageToStorageForCard
} from "../utils/StorageUtil";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Profile.css";
import Typography from "@mui/material/Typography";
import {
  addMissingLearningDeck,
  addNewCard,
  getDeckAndCards,
  updateExistingDeck
} from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";
import { CardEditList } from "../dayun-ui-compontents/card/CardEditList";
import ProgressDialog from "../dayun-ui-compontents/dialog/ProgressDialog";
import { canDeckBeEditedByTheUser } from "../utils/ValidationUtil";
import { RoutePathName } from "../constant";
import { IconButton, Tooltip } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

function DeckPage({ signOut, user }) {
  const { id } = useParams();

  const [deck, setDeck] = useState(undefined);
  const [cards, setCards] = useState(undefined);

  const [createCardDialogOpen, setCreateCardDialogOpen] = useState(false);
  const [editedDeck, setEditedDeck] = useState(undefined);
  const [progressDialog, setProgressDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const sortedCards = useMemo(() => {
    if (cards) {
      return [...cards].sort((a, b) => {
        const compareResult = a.createdAt.localeCompare(b.createdAt);
        return sortOrder === "asc" ? compareResult : -compareResult;
      });
    }
    return cards;
  }, [cards, sortOrder]);

  const handleGoBack = () => {
    if (loading || canEdit) {
      navigate(`${RoutePathName.DECKS}`);
    } else {
      navigate(`${RoutePathName.SHARE_DECKS}`);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 1000);
    getDeckAndCards(user, id)
      .then((r) => {
        setCards(r.cards);
        // Asynchronously add the deck to the user's learning deck list if missing learning_deckId
        addMissingLearningDeck(r.deck, user.attributes.sub).then(
          (resultingDeck) => {
            setDeck(resultingDeck);
          }
        );
      })
      .catch((error) => {
        alertForApiError(error);
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (user === null || deck === undefined) {
      return;
    }
    if (canDeckBeEditedByTheUser(deck, user)) {
      setCanEdit(true);
    }
  }, [user, deck]);

  function handleAddCard(props) {
    const userId = user.attributes.sub;
    setCreateCardDialogOpen(false);
    let promise = Promise.resolve();
    if (props.question instanceof File) {
      setProgressDialog(true);

      const file = props.question;
      promise = saveImageToStorageForCard(userId, file);
    }
    promise
      .then((result) => {
        const question = result
          ? encodeImageKeyInFlashcard(result.key)
          : props.question;
        return addNewCard(
          { ...props, question },
          id,
          userId,
          cards.length
        ).then((card) => {
          setCards([...cards, card]);
        });
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setProgressDialog(false);
      });
  }

  async function handleEditDeck(deck, props) {
    updateExistingDeck(user.attributes.sub, deck, props)
      .then((updatedDeck) => {
        setDeck(updatedDeck);
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setEditedDeck(undefined);
      });
  }

  function getButtonsUi(deck) {
    if (deck === undefined) {
      if (loading) {
        return <ProgressDialog open={loading} title={"Loading..."} />;
      } else {
        return <></>;
      }
    }
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            paddingRight: "18px",
            paddingTop: "20px"
          }}
        >
          {canEdit && (
            <Fab
              variant="extended"
              onClick={() => setCreateCardDialogOpen(true)}
              sx={{
                color: "#282d35",
                fontWeight: "bold",
                fontSize: "22px",
                right: "160px",
                marginTop: "20px",
                backgroundColor: "#deebf8",
                position: "relative",
                transition: "background-color 0.2s ease-in-out",
                "&:hover::before": {
                  content: '"Add New Card"',
                  position: "absolute",
                  top: "50px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: "3px",
                  fontSize: "14px",
                  backgroundColor: "#282d35",
                  color: "#deebf8",
                  borderRadius: "4px"
                },
                "@media (max-width: 630px)": {
                  justifyContent: "center",
                  position: "absolute",
                  right: "110px",
                  marginTop: "0px",
                  marginLeft: "10px",
                  fontSize: "14px",
                  "&:hover::before": {
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    padding: "3px",
                    fontSize: "12px",
                    backgroundColor: "#282d35",
                    color: "#deebf8",
                    borderRadius: "4px"
                  },
                  "@media (max-width: 629px)": {
                    display: "none"
                  }
                }
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#b5c5e5";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#deebf8";
              }}
            >
              <AddIcon
                sx={{
                  mr: 1,
                  ml: 1,
                  "@media (max-width: 700px)": {
                    mr: 0,
                    ml: 0
                  },
                  "@media (max-width: 420px)": {
                    width: 17,
                    height: 17,
                    fontSize: 5
                  }
                }}
              />
            </Fab>
          )}
          <Fab
            variant="extended"
            onClick={handleSortChange}
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              backgroundColor: "#deebf8",
              marginTop: "20px",
              position: "absolute",
              right: "87px",
              fontSize: "17.5px",
              transition: "background-color 0.2s ease-in-out",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover::before": {
                content: '"Sort Cards"',
                position: "absolute",
                top: "50px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "3px",
                fontSize: "14px",
                backgroundColor: "#282d35",
                color: "#deebf8",
                borderRadius: "4px"
              },
              "@media (max-width: 630px)": {
                alignContent: "center",
                position: "absolute",
                right: "15px",
                marginTop: "0px",
                marginLeft: "10px",
                fontSize: "14px",
                "&:hover::before": {
                  top: "-10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: "3px",
                  fontSize: "12px",
                  backgroundColor: "#282d35",
                  color: "#deebf8",
                  borderRadius: "4px"
                },
                "@media (max-width: 629px)": {
                  display: "none"
                }
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <SortIcon
              sx={{
                mr: 1,
                ml: 1,
                transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})`,
                "@media (max-width: 700px)": {
                  mr: 0,
                  ml: 0
                },
                "@media (max-width: 420px)": {
                  width: 17,
                  height: 17,
                  fontSize: 5
                }
              }}
            />
          </Fab>
          <Fab
            onClick={handleGoBack}
            variant="extended"
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              backgroundColor: "#deebf8",
              position: "absolute",
              left: "30px",
              marginTop: "24px",
              fontSize: "17.5px",
              transition: "background-color 0.2s ease-in-out",
              "@media (max-width: 499px)": {
                fontSize: "12px",
                width: "10%",
                height: "40px",
                marginTop: "14px",
                justifyContent: "center",
                position: "absolute",
                left: "22px"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <ArrowBackIcon sx={{ mr: 0.5 }} />
          </Fab>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div
            tabIndex={0}
            style={{
              width: "85%",
              maxWidth: 900,
              marginTop: 90,
              marginBottom: 10,
              backgroundColor: "#282d35",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h4"
                align="left"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "#deebf8",
                  fontSize: "26px",
                  marginTop: "35px",
                  "@media (max-width: 499px)": {
                    fontSize: "16px",
                    marginTop: "8px",
                    textAlign: "left"
                  }
                }}
              >
                Deck: {deck && deck.name}
              </Typography>
              <Tooltip
                title="Study the cards in this deck"
                arrow
                placement="top"
              >
                <IconButton
                  onClick={() => navigate(`/learn/${deck.id}`)}
                  aria-label="Study"
                  sx={{
                    color: "#deebf8",
                    marginTop: "26px",
                    "@media (max-width: 499px)": {
                      marginTop: "0px"
                    }
                  }}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        paddingLeft: "60px",
        margin: "0 auto",
        "@media (max-width: 1281px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      }}
    >
      <CreateDeckDialog
        user={user}
        currentDeck={editedDeck}
        onCreateDeck={(props) => handleEditDeck(editedDeck, props)}
        onCloseDialog={() => setEditedDeck(undefined)}
        open={editedDeck !== undefined}
      />

      {getButtonsUi(deck)}

      <CreateCardDialog
        onCreateCard={(props) => handleAddCard(props)}
        onCloseDialog={() => setCreateCardDialogOpen(false)}
        open={createCardDialogOpen}
      />

      <CardEditList
        inputCards={sortedCards}
        user={user}
        deck={deck}
        onDeletedAllCards={() => {
          setCards([]);
        }}
      />
      {cards !== undefined && cards.length === 0 ? (
        <NoItemFound msg={"No card founds"}></NoItemFound>
      ) : null}

      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& > :not(style)": { m: 1 }
          }}
        >
          {canEdit && (
            <Fab
              variant="extended"
              onClick={() => setCreateCardDialogOpen(true)}
              sx={{
                color: "#282d35",
                fontWeight: "bold",
                fontSize: "17.5px",
                backgroundColor: "#deebf8",
                position: "absolute",
                right: "120px",
                transition: "background-color 0.2s ease-in-out",
                "@media (min-width: 630px)": {
                  display: "none"
                },
                "@media (max-width: 629px)": {
                  visibility: "visible",
                  fontSize: "14px",
                  height: "30px",
                  justifyContent: "center",
                  position: "fixed",
                  bottom: 60,
                  right: 11,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end"
                }
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#b5c5e5";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#deebf8";
              }}
            >
              <AddIcon sx={{ m: 1, "@media (max-width: 629px)": { m: 0.1 } }} />
            </Fab>
          )}
          <Fab
            variant="extended"
            onClick={handleSortChange}
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              fontSize: "17.5px",
              backgroundColor: "#deebf8",
              position: "absolute",
              right: "28px",
              transition: "background-color 0.2s ease-in-out",
              "@media (min-width: 630px)": {
                display: "none"
              },
              "@media (max-width: 629px)": {
                visibility: "visible",
                fontSize: "14px",
                height: "30px",
                justifyContent: "center",
                position: "fixed",
                bottom: 20,
                right: 11,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <SortIcon sx={{ m: 1, "@media (max-width: 629px)": { m: 0.1 } }} />
          </Fab>
        </Box>
      </>
    </Box>
  );
}

export default withDayunAuthenticator(DeckPage);
