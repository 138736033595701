import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Limitation } from "../../constant";
import { translateQA } from "../../utils/ApiUtil";
import { useCounter, useDebounce } from "react-use";
import { isInputStringImageEncoded } from "../../utils/StorageUtil";
import { IconButton } from "@mui/material";

const TEXT_FOR_GENERATE_BUTTON = "Generate Answer";
export default function CreateCardDialog({
  open,
  onCloseDialog,
  onCreateCard,
  currentCard
}) {
  const [question, setQuestion] = useState(
    currentCard ? currentCard.question : undefined
  );
  const [answer, setAnswer] = useState(
    currentCard ? currentCard.answer : undefined
  );
  // Generate answer button hooks
  const [shouldGenerateAns, setShouldGenerateAns] = useState(false);
  const [generateAnsButtonCount, { inc: incrementGenerateAnsButtonCount }] =
    useCounter(10);
  const [isGenerateAnswerTyping, setIsGenerateAnswerTyping] = useState(false);
  const [generateButtonText, setGenerateButtonText] = useState(
    TEXT_FOR_GENERATE_BUTTON
  );
  const [isCreationDisabled, setIsCreationDisabled] = useState(false);

  useMemo(() => {
    if (
      question !== undefined &&
      question.length > 0 &&
      generateAnsButtonCount > 0 &&
      isInputStringImageEncoded(question) === false
    ) {
      setShouldGenerateAns(true);
      setGenerateButtonText(TEXT_FOR_GENERATE_BUTTON);
    } else {
      setShouldGenerateAns(false);
    }
  }, [question]);

  useEffect(() => {
    let timeoutId;
    const type = () => {
      if (!isGenerateAnswerTyping) {
        return;
      }

      const text = generateButtonText + (timeoutId % 2 === 0 ? ".." : "...");
      setGenerateButtonText(text);
      timeoutId = setTimeout(type, 500);
    };
    type();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isGenerateAnswerTyping]);

  const [,] = useDebounce(
    () => {
      if (isGenerateAnswerTyping) {
        console.log("Generating answer skip request");
        return;
      }
      if (shouldGenerateAns) {
        setIsGenerateAnswerTyping(true);
        translateQA(question, "null", "null")
          .then((data) => {
            if (data && data.answer) {
              setAnswer(data.answer);
            }
          })
          .finally(() => {
            setIsGenerateAnswerTyping(false);
            setGenerateButtonText(TEXT_FOR_GENERATE_BUTTON);
          });
      }
    },
    500,
    [generateAnsButtonCount]
  );

  useEffect(() => {
    setQuestion(currentCard ? currentCard.question : undefined);
    setAnswer(currentCard ? currentCard.answer : undefined);
  }, [currentCard, open]);

  const dialogTitle = currentCard ? "Edit Card" : "Create a card";
  const submitBtnText = currentCard ? "Update" : "Create";

  const useStyles = {
    button: {
      marginTop: "5px",
      visibility: shouldGenerateAns ? "visible" : "hidden",
      background: "#282d35",
      paddingLeft: "1px",
      minWidth: "200px",
      color: "#deebf8",
      transition: "background-color 0.2s ease-in-out"
    }
  };

  function showGenerateAnsButton() {
    return (
      <Button
        style={useStyles.button}
        disabled={isGenerateAnswerTyping}
        onClick={() => {
          incrementGenerateAnsButtonCount(1);
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#40464e";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#282d35";
        }}
      >
        {generateButtonText}
      </Button>
    );
  }

  function renderSwapQAIcon() {
    if (
      isInputStringImageEncoded(question) ||
      isInputStringImageEncoded(answer)
    ) {
      return;
    }

    return (
      <IconButton
        onClick={() => {
          if (
            question === undefined ||
            answer === undefined ||
            question.length === 0 ||
            answer.length === 0
          ) {
            return;
          }
          setQuestion(answer);
          setAnswer(question);
        }}
        sx={{
          position: "absolute",
          top: "50%",
          right: "20px",
          transform: "translateY(-50%)"
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M9 3L5 7h3v7h2V7h3m3 10v-7h-2v7h-3l4 4l4-4h-3Z"
          />
        </svg>
      </IconButton>
    );
  }

  return (
    <div>
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle
          sx={{
            background: "#deebf8",
            fontSize: "31px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#282d35",
            "@media (max-width: 499px)": {
              fontSize: "25px"
            },
            position: "relative"
          }}
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{ background: "#deebf8" }}>
          <div style={{ padding: "10px" }}>
            <TextField
              autoFocus
              id="Question"
              label="Question"
              multiline
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: Limitation.MAX_LEN_QUESTION }}
              defaultValue={question}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            {renderSwapQAIcon()}
            <TextField
              style={{ marginTop: "20px" }}
              id="Answer"
              label="Answer"
              multiline
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: Limitation.MAX_LEN_ANSWER }}
              defaultValue={currentCard ? currentCard.answer : undefined}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            {showGenerateAnsButton()}
          </div>
        </DialogContent>
        <DialogActions style={{ background: "#deebf8" }}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button
            disabled={isCreationDisabled}
            onClick={() => {
              setIsCreationDisabled(true);
              onCreateCard({ question, answer });
              setTimeout(() => {
                setIsCreationDisabled(false);
              }, 300);
            }}
          >
            {submitBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
