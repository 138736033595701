import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme} from "@mui/material";

import DeleteConfirmDialog from "../dialog/DeleteConfirmDialog";
import { deleteExistingCard, updateExistingCard } from "../../utils/ApiUtil";
import { canDeckBeEditedByTheUser } from "../../utils/ValidationUtil";
import {getCardQAEditWebForm} from "./CardQAEditWebForm";
import {getCardQAEditMobileForm} from "./CardQAEditMobileForm";
import {DefaultApiStatus, StatusType} from "../../utils/ApiStatus";



export function CardEditList({ inputCards, user, deck, onDeletedAllCards }) {
  const [editingCardIndex, setEditingCardIndex] = useState(null);
  const [deleteConfirmOpen, setDeleteDeleteConfirmOpen] = React.useState(false);
  const [deleteCardApiStatus, setDeleteCardApiStatus] = useState(DefaultApiStatus);
  const [cards, setCards] = useState(inputCards);
  const [cardIdToOriginalQA, setCardIdToOriginalQA] = useState(new Map());
  const [canEdit, setCanEdit] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  useEffect(() => {
    if (inputCards === null || inputCards === undefined) {
      return;
    }
    for (const c of inputCards) {
      cardIdToOriginalQA.set(c.id, { question: c.question, answer: c.answer });
    }
    setCardIdToOriginalQA(cardIdToOriginalQA);

    setCards(inputCards);
  }, [inputCards]);

  useEffect(() => {
    if (user === null || deck === undefined) {
      return;
    }
    if (canDeckBeEditedByTheUser(deck, user)) {
      setCanEdit(true);
    }
  }, [user, deck]);

  const handleClickSaveCard = (event, index) => {
    const editingCard = cards[index];
    cardIdToOriginalQA.set(editingCard.id, {
      question: editingCard.question,
      answer: editingCard.answer
    });
    // Update the card from the database in async way
    updateExistingCard(user.attributes.sub, deck, editingCard, {
      question: editingCard.question,
      answer: editingCard.answer
    })
      .then(() => {
        console.log("Card updated");
      })
      .catch((error) => {
        console.log("Error updating card", error);
      });
  };

  const handleDiscard = (e, card) => {
    const originalQA = cardIdToOriginalQA.get(card.id);
    card.question = originalQA.question;
    card.answer = originalQA.answer;
    setCards([...cards]);
  };

  const handleCloseDeleteConfirmationClick = (deleted) => {
    setDeleteDeleteConfirmOpen(false);
    if (deleted) {
      setDeleteCardApiStatus({ status_type: StatusType.LOADING });
      const editingCard = cards[editingCardIndex];
      cards.splice(editingCardIndex, 1);
      setCards([...cards]);
      // Delete the card from the database in async way
      deleteExistingCard(user.attributes.sub, deck, editingCard)
        .then((response) => {
          console.log("Card deleted");
          setDeleteCardApiStatus({ status_type: StatusType.SUCCEEDED });
          if (cards.length === 0) {
            onDeletedAllCards();
          }
        })
        .catch((error) => {
          console.log("Error deleting card", error);
          setDeleteCardApiStatus({ status_type: StatusType.FAILED , message: "Failed to delete the card" });
        });
    }
  };

  return (
    cards && (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <DeleteConfirmDialog
          isDeleting={deleteCardApiStatus.status_type === StatusType.LOADING}
          open={deleteConfirmOpen}
          message={"Are you sure you want to delete this card?"}
          handleCloseDeleteConfirmationClick={(deleted) =>
            handleCloseDeleteConfirmationClick(deleted)
          }
        />
        <div
          style={{
            width: "100%",
            maxWidth: 925,
            marginBottom: 80,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            marginLeft: 30,
            marginRight: "20px"
          }}
        >
          {cards.map((card, index) =>
             (isMobile ?
              getCardQAEditMobileForm(index, canEdit, card, setEditingCardIndex, cards, setCards, handleDiscard, setDeleteDeleteConfirmOpen, handleClickSaveCard) :
            getCardQAEditWebForm(index, canEdit, card, setEditingCardIndex, cards, setCards, handleDiscard, setDeleteDeleteConfirmOpen, handleClickSaveCard))
          )}
        </div>
      </Box>
    )
  );
}
