import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { ContactSupport } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { RoutePathName } from "../../constant";
import { getUserDisplayName } from "../../utils/UserUtil";
import { getUserProfilePicture } from "../../utils/StorageUtil";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LockPersonIcon from "@mui/icons-material/LockPerson";

export default function AccountMenu({ user, singOut }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayUsrName, _] = useState(getUserDisplayName(user));
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);

  const open = Boolean(anchorEl);
  const handleTriggerIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function fetchUserData() {
      if (user) {
        const profilePictureS3Link = await getUserProfilePicture(user);
        setProfilePictureUrl(profilePictureS3Link);
      }
    }

    fetchUserData();
  }, [user]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip>
          <IconButton
            onClick={handleTriggerIcon}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 46, height: 46, backgroundColor: "#004aad" }}
              alt={displayUsrName}
              src={profilePictureUrl}
            >
              {displayUsrName[0]}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "#DEEBF8",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => navigate(RoutePathName.PROFILE)}
          style={{ backgroundColor: "#DEEBF8" }}
        >
          <Avatar src={profilePictureUrl} /> Profile
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => navigate(RoutePathName.ACHIEVEMENT)}>
          <ListItemIcon>
            <EmojiEventsIcon fontSize="medium" />
          </ListItemIcon>
          Achievement
        </MenuItem>

        <MenuItem onClick={() => navigate(RoutePathName.CONTACT)}>
          <ListItemIcon>
            <ContactSupport fontSize="medium" />
          </ListItemIcon>
          Contact Form
        </MenuItem>

        <MenuItem onClick={() => singOut()}>
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => navigate(RoutePathName.PRIVACY)}>
          <ListItemIcon>
            <LockPersonIcon fontSize="medium" />
          </ListItemIcon>
          Privacy Policy
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
