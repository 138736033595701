import React, { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { notifyUs } from "../utils/ApiUtil";
import { createGlobalStyle } from "styled-components";
import "./Profile.css";

export function getAboutUsInformationBoxComp() {
  return (
    <Box
      sx={{
        color: "#DEEBF8",
        fontSize: "19px",
        "@media (max-width: 499px)": {
          fontSize: "15px",
          marginBottom: "40px"
        }
      }}
    >
      Welcome to <strong>FlashLearn</strong>, the cutting-edge application
      powered by AI and spaced repetition to revolutionize the way you acquire
      and retain knowledge! As the pioneer flashcard app that utilizes AI to
      generate answers to questions, we are proud to offer a unique and
      innovative approach to learning. Our spaced repetition technology
      guarantees efficient and effective retention of information. Our team of
      passionate developers is dedicated to providing an enjoyable and
      personalized experience for users of all ages. We firmly believe that
      anyone can achieve their learning goals with the right tools, and our app
      is precisely that. <strong>FlashLearn</strong> is your perfect learning
      partner, whether you're a student, a professional, or seeking to boost
      your memory. Thank you for choosing <strong>FlashLearn</strong>, and we
      look forward to accompanying you on your learning expedition!
    </Box>
  );
}
function isValidEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function Contact() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [subject, setSubject] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  function handleSubmit(e) {
    e.preventDefault();
    const notifyData = { name, email, subject, message };
    if (name && email && subject && message) {
      if (!isValidEmail(email)) {
        alert("Please enter a valid email address.");
        return;
      }
      if (name.length > 50) {
        alert("Name is too long.");
        return;
      }
      if (subject.length > 50) {
        alert("Subject is too long.");
        return;
      }
      if (message.length > 500) {
        alert("Message is too long.");
        return;
      }
      if (message.length < 10) {
        alert("Message is too short.");
        return;
      }

      notifyUs(notifyData)
        .then((res) => {
          setOpen(true);
        })
        .catch((err) => {
          alert("Something went wrong. Please try again later.");
        });
    } else {
      alert("Please fill in all the fields.");
    }
  }

  function handleOK() {
    setOpen(false);
    window.location.reload();
  }

  const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
    .Audiowide {
        font-family: 'Audiowide', sans-serif;
    }
`;
  return (
    <>
      <GlobalStyle />
      <div
        style={{
          height: "100vh",
          marginBottom: "120px",
          display: "flex",
          justifyContent: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          boxSizing: "border-box"
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: "650px",
            marginTop: "40px",
            "@media (max-width: 499px)": {
              maxWidth: "350px",
              marginTop: "10px"
            }
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "#DEEBF8",
                "@media (max-width: 499px)": {
                  fontSize: "19px"
                }
              }}
              className="Audiowide"
              gutterBottom
            >
              CONTACT US
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form sx={{ marginTop: "3px" }}>
              <TextField
                inputProps={{ maxLength: 50, minLength: 1 }}
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
                sx={{
                  backgroundColor: "#DEEBF8",
                  fontSize: "20px",
                  "@media (max-width: 499px)": {
                    fontSize: "12px"
                  }
                }}
              />
              <TextField
                inputProps={{
                  type: "email",
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  maxLength: 255
                }}
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  backgroundColor: "#DEEBF8",
                  fontSize: "20px", // Set a default font size
                  "@media (max-width: 499px)": {
                    fontSize: "12px"
                  }
                }}
              />
              <TextField
                inputProps={{ maxLength: 50, minLength: 1 }}
                label="Subject"
                variant="outlined"
                fullWidth
                margin="normal"
                name="subject"
                required
                onChange={(e) => setSubject(e.target.value)}
                sx={{
                  backgroundColor: "#DEEBF8",
                  fontSize: "20px", // Set a default font size
                  "@media (max-width: 499px)": {
                    fontSize: "12px"
                  }
                }}
              />
              <TextField
                inputProps={{ maxLength: 150, minLength: 1 }}
                label="Message"
                variant="outlined"
                fullWidth
                margin="normal"
                name="message"
                multiline
                rows={4}
                required
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  backgroundColor: "#DEEBF8",
                  fontSize: "20px", // Set a default font size
                  "@media (max-width: 499px)": {
                    fontSize: "12px"
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => handleSubmit(e)}
                sx={{
                  backgroundColor: "#5ce1e6",
                  color: "#282d35",
                  fontFamily: "Yantramanav, sans-serif",
                  fontSize: "17px",
                  marginTop: "15px",
                  transition: "background-color 0.2s ease-in-out",
                  "@media (max-width: 499px)": {
                    fontSize: "13.5px",
                    padding: "8px 10px"
                  }
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#a9edef";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#5ce1e6";
                }}
              >
                Submit
              </Button>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "#DEEBF8",
                marginTop: "20px",
                "@media (max-width: 499px)": {
                  fontSize: "19px",
                  marginTop: "10px"
                }
              }}
              className="Audiowide"
            >
              ABOUT US
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {getAboutUsInformationBoxComp()}
          </Grid>
          <Dialog open={open} onClose={handleOK}>
            <DialogTitle>Submit Successful</DialogTitle>
            <DialogContent>Your message has been sent.</DialogContent>
            <DialogActions>
              <Button onClick={handleOK} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </>
  );
}

export default Contact;
