import React, { useEffect, useState } from "react";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import "./Profile.css";
import { getUserAchievement } from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";
import ProgressDialog from "../dayun-ui-compontents/dialog/ProgressDialog";
import { Grid, CircularProgress, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./Achievement.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { RoutePathName } from "../constant";
import NoItemFound from "../dayun-ui-compontents/NoItemFound";
import GoogleAd from "./GoogleAd";

const Achievement = ({ signOut, user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deckAchievements, setDeckAchievements] = useState(undefined);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 1000);
    getUserAchievement(user)
      .then((data) => {
        setDeckAchievements(data);
      })
      .catch((error) => {
        alertForApiError(error);
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoading(false);
      });
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#5271ff"
      },
      secondary: {
        main: "#f7bb3f"
      },
      third: {
        main: "#ff6565"
      },
      forth: {
        main: "#00bf63"
      }
    }
  });
  const secColor = "#d1d1d1",
    thickness = 5,
    size = 100;

  const progressSx = {
    marginBottom: "15px",
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 ${(thickness / 44) * size}px ${secColor}`
  };

  const handleClickTitle = (deck) => {
    navigate(`${RoutePathName.DECK}/` + deck.id);
  };

  return (
    <ThemeProvider theme={theme}>
      <ProgressDialog open={loading} title={"Loading..."} />
      <div className="root">
        <Grid container spacing={5} alignItems="center" justifyContent="center">
          {deckAchievements !== undefined && deckAchievements.length === 0 && (
            <NoItemFound msg={"No achievement yet"} />
          )}
          {deckAchievements &&
            deckAchievements.map((deck) => (
              <Grid item xs={12} sm={8} md={6} lg={4} key={deck.name}>
                <div className="deckColumn" style={{ textAlign: "center" }}>
                  <div
                    onClick={(event) => handleClickTitle(deck)}
                    className="deckTitle"
                  >
                    {deck.name}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block"
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={deck.mastery}
                      size={size}
                      thickness={thickness}
                      sx={progressSx}
                      style={{ display: "block", margin: "0 auto" }}
                    />
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        color: "#deebf8",
                        fontSize: "22px",
                        fontWeight: "bold",
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1
                      }}
                    >
                      {deck.mastery}%
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      style={{
                        color: "#deebf8",
                        fontSize: "14px",
                        position: "absolute",
                        top: "60%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1
                      }}
                    >
                      MASTERY
                    </Typography>
                  </div>
                  <div
                    className="progressContainer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "15px"
                    }}
                  >
                    <div
                      className="progressItem"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "85%"
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: "#deebf8",
                          flex: 1,
                          width: "45px"
                        }}
                      >
                        Easy
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={(deck.easy / deck.totalDifficultyCount) * 100}
                        sx={{
                          width: "60%",
                          height: 10,
                          borderRadius: 5,
                          marginLeft: "1.5rem",
                          marginRight: "0.5rem",
                          color: "#ff6565",
                          backgroundColor: "#deebf8",
                          "@media (max-width: 599px)": {
                            margin: "0 1rem",
                            width: "49%"
                          }
                        }}
                        color="forth"
                      />
                      <Typography
                        variant="body1"
                        style={{
                          color: "#deebf8",
                          width: "20px",
                          marginLeft: "5px"
                        }}
                      >
                        {deck.easy}
                      </Typography>
                    </div>
                    <div
                      className="progressItem"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "85%"
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ color: "#deebf8", flex: 1, width: "45px" }}
                      >
                        Medium
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={(deck.medium / deck.totalDifficultyCount) * 100}
                        sx={{
                          width: "60%",
                          height: 10,
                          borderRadius: 5,
                          marginLeft: "1.5rem",
                          marginRight: "0.5rem",
                          color: "#ff6565",
                          backgroundColor: "#deebf8",
                          "@media (max-width: 599px)": {
                            margin: "0 1rem",
                            width: "49%"
                          }
                        }}
                        color="secondary"
                      />
                      <Typography
                        variant="body1"
                        style={{
                          color: "#deebf8",
                          width: "20px",
                          marginLeft: "5px"
                        }}
                      >
                        {deck.medium}
                      </Typography>
                    </div>
                    <div
                      className="progressItem"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "85%"
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ color: "#deebf8", flex: 1, width: "45px" }}
                      >
                        Hard
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={(deck.hard / deck.totalDifficultyCount) * 100}
                        sx={{
                          width: "60%",
                          height: 10,
                          borderRadius: 5,
                          marginLeft: "1.5rem",
                          marginRight: "0.5rem",
                          color: "#ff6565",
                          backgroundColor: "#deebf8",
                          "@media (max-width: 599px)": {
                            margin: "0 1rem",
                            width: "49%"
                          }
                        }}
                        color="third"
                      />
                      <Typography
                        variant="body1"
                        style={{
                          color: "#deebf8",
                          width: "20px",
                          marginLeft: "5px"
                        }}
                      >
                        {deck.hard}
                      </Typography>

                      <GoogleAd
                        slot="6801610205"
                        googleAdId="ca-pub-4485829436663574"
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default withDayunAuthenticator(Achievement);
