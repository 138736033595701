import {
  Authenticator,
  Button,
  Heading,
  useAuthenticator,
  useTheme,
  View
} from "@aws-amplify/ui-react";
import { SUPPORTED_SOCIAL_PROVIDERS } from "../constant";
import React from "react";

const components = {
  Header() {
    const { tokens } = useTheme();

    return <View textAlign="center" padding={tokens.space.large}></View>;
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Heading style={{ fontSize: 12, color: "#deebf8" }}>
          &copy; {new Date().getFullYear()} DaYun Tech. All Rights Reserved.
        </Heading>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Forgot your password?
          </Button>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    }
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      return <Heading></Heading>;
    }
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      return <Heading></Heading>;
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      return <Heading></Heading>;
    }
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading textAlign="center" level={3} color={tokens.colors.neutral[80]}>
          Reset your password
        </Heading>
      );
    },

    Footer() {
      return <Heading></Heading>;
    }
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      return <Heading></Heading>;
    }
  }
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
      isRequired: true,
      label: "Email:"
    },
    password: {
      placeholder: "Enter your password",
      isRequired: true,
      label: "Password:"
    }
  },
  signUp: {
    preferred_username: {
      label: "Username:",
      placeholder: "Enter your username",
      order: 1
    },
    email: {
      label: "Email:",
      placeholder: "Enter your email",
      order: 2
    },
    password: {
      label: "Password:",
      placeholder: "Enter your password",
      isRequired: false,
      order: 3
    },
    confirm_password: {
      label: "Confirm password:",
      placeholder: "Please confirm your password",
      order: 4
    }
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your password"
    }
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email:",
      label:
        "Enter your email address you signed up with. We will send you a code to reset your password."
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your confirmation code:",
      label: "New Label",
      isRequired: false
    },
    confirm_password: {
      placeholder: "Enter your password"
    }
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user"
    },
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your confirmation code:",
      isRequired: false
    }
  },
  confirmSignIn: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your confirmation code:",
      isRequired: false
    }
  }
};
export const withDayunAuthenticator = (WrappedComponent) => {
  return (props) => {
    return (
      <Authenticator
        socialProviders={SUPPORTED_SOCIAL_PROVIDERS}
        formFields={formFields}
        components={components}
      >
        {({ signOut, user }) => (
          <WrappedComponent {...props} signOut={signOut} user={user} />
        )}
      </Authenticator>
    );
  };
};
