import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = {
  root: {
    height: "100vh",
    background: "282d35"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  img: {
    width: "40%",
    marginBottom: "20px"
  },
  text: {
    textAlign: "center",
    marginBottom: "10px",
    color: "#DEEBF8"
  },
  link: {
    textDecoration: "none",
    color: "#3f51b5"
  },
  button: {
    marginTop: "20px",
    background: "#5ce1e6",
    color: "#282d35",
    "&:hover": {
      background: "#1a237e"
    }
  }
};

export default function NotFound() {
  return (
    <Grid container style={useStyles.root}>
      <Grid item xs={12} style={useStyles.container}>
        <Typography variant="h4" style={useStyles.text}>
          Oops! Page Not Found
        </Typography>
        <Typography variant="subtitle1" style={useStyles.text}>
          Sorry, the page you were looking for could not be found.
        </Typography>
        <Link to="/" style={useStyles.link}>
          <Button variant="contained" style={useStyles.button}>
            Go to Homepage
          </Button>
        </Link>

        <Link to="/contact" style={useStyles.link}>
          <Button variant="contained" style={useStyles.button}>
            Contact us
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
