import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";

export function CardList({ cards }) {
  const numberOfCards = cards.length;
  const [showCardsList, setShowCardsList] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingLeft: "21px",
        paddingRight: "14px",
        boxSizing: "border-box",
        marginBottom: "60px",
        "@media (max-width: 499px)": {
          paddingLeft: "23px",
          paddingRight: "14px"
        }
      }}
    >
      <div
        tabIndex={0}
        style={{
          width: "85%",
          maxWidth: 900,
          marginTop: 100,
          backgroundColor: "#282d35",
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          flexDirection: "column"
        }}
      >
        <Divider
          sx={{
            borderTop: "0.5px solid #deebf8",
            mb: 2,
            width: "98.5%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
          }}
        />
        <Typography
          variant="h5"
          align="left"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            color: "#deebf8",
            fontSize: "18px",
            marginBottom: "20px",
            "@media (max-width: 499px)": {
              fontSize: "14px",
              marginBottom: "5px",
              textAlign: "center"
            }
          }}
        >
          <button
            onClick={() => setShowCardsList(!showCardsList)}
            style={{
              backgroundColor: "transparent",
              color: "#deebf8",
              "&:hover": {
                backgroundColor: "#616a7f",
                color: "#fff"
              },
              border: "none",
              cursor: "pointer"
            }}
          >
            CLICK TO VIEW ALL CARDS IN THIS DECK ({numberOfCards})
          </button>
        </Typography>
        {showCardsList && (
          <List sx={{ width: "101%", marginTop: "25px" }}>
            {cards.map((card, index) => (
              <ListItem key={index} sx={{ marginBottom: 1.4 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#deebf8",
                      backgroundColor: "#3c4252"
                    }}
                  >
                    <ListItemText primary={card.question} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#deebf8",
                      backgroundColor: "#3c4252",
                      borderLeft: "none",
                      paddingLeft: 2,
                      borderTop: "none",
                      "@media (min-width: 821px)": {
                        borderLeft: "1.7px solid #b5c5e5",
                        borderTop: "none",
                        marginTop: "2px",
                        paddingLeft: "12px",
                        position: "relative"
                      },
                      "@media (max-width: 820px)": {
                        borderTop: "1.7px solid #b5c5e5",
                        borderLeft: "none"
                      }
                    }}
                  >
                    <ListItemText primary={card.answer} />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </Box>
  );
}
