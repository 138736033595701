export const StatusType = {
  BASE: 0,
  SUCCEEDED: 1,
  FAILED: 2,
  LOADING: 3,
};

export const DefaultApiStatus = {
  status_type: StatusType.BASE,
  header: undefined,
  message: undefined,
};
