import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  createGeneralCard,
  createGeneralDeck,
  createMyLearningCard,
  createMyLearningDeck
} from "../graphql/mutations";
import {
  isOwnerByOthersButShared,
  validateCardCreation,
  validateDeckCreation,
  validateDeckUpdate
} from "./ValidationUtil";
import {
  isInputStringImageEncoded,
  retrieveImageFromStorage
} from "./StorageUtil";
import { DeckOwnerType, DEFAULT_CARDS_TO_LEARN_PER_ROUND } from "../constant";
import { getAchievementForDeckAndItsCards } from "./DeckUtil";

const API_NAME = "flashcardbackend";

class RestApiPath {
  static LINE_NOTIFY = "/linenotify";
  static TRANSLATE = "/translate";
  static DECK = "/deck";
  static CARD = "/card";
  static SEARCH = "/search";
}

export const notifyUs = async (input) => {
  const init = {
    queryStringParameters: input
  };
  // console.log("notifyUs", init);
  return API.post(API_NAME, RestApiPath.LINE_NOTIFY, init);
};

export const translateQA = async (question, sourceLocale, targetLocale) => {
  const init = {
    queryStringParameters: {
      question: question,
      sourceLocale: sourceLocale,
      targetLocale: targetLocale
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.post(API_NAME, RestApiPath.TRANSLATE, init);
};

/**
 * Get deck and cards by deck id and user id
 * Return a promise of the below data
 * {
 *   "deck": {},
 *   "cards": [{}],
 *   "error: ""
 * }
 */
export const getDeckAndCards = async (user, deckId) => {
  const init = {
    queryStringParameters: {
      userId: user.attributes.sub,
      deckId: deckId
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.get(API_NAME, RestApiPath.DECK, init);
};

/**
 * Get decks and its cards by deck ids and user id. It is the same as getDeckAndCards but for multiple decks
 * Return a promise of the below data
 * [{
 *   "deck": {},
 *   "cards": [{}],
 *   "error: ""
 * }]
 */
export const getDecksAndCards = async (user, deckIds) => {
  if (deckIds.length === 0) {
    return [];
  }
  const init = {
    queryStringParameters: {
      userId: user.attributes.sub,
      deckIds: deckIds.join(",")
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.get(API_NAME, RestApiPath.DECK, init);
};

export const getDecksForUser = async (
  user,
  deckOwnerType = DeckOwnerType.OWNER
) => {
  const init = {
    queryStringParameters: {
      userId: user.attributes.sub,
      deckOwnerType
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.get(API_NAME, RestApiPath.DECK, init);
};

export const getUserAchievement = async (user) => {
  return getDecksForUser(user, DeckOwnerType.ALL)
    .then((data) => {
      const deckIds = data.decks.map((deck) => deck.id);
      return getDecksAndCards(user, deckIds);
    })
    .then((decksAndCards) => {
      return getAchievementForDeckAndItsCards(decksAndCards);
    });
};

// ===================== Deck operations =====================

/**
 * Update Deck and UserDeck
 */
export const addNewDeck = async (deck, user, currentDeckSize) => {
  const rejectPromise = validateDeckCreation(deck, currentDeckSize);
  if (rejectPromise !== undefined) {
    return rejectPromise;
  }
  return API.graphql(
    graphqlOperation(createGeneralDeck, {
      input: {
        name: deck.name,
        description: deck.description,
        subject: deck.subject,
        isShared: deck.isShared,
        isSharedEditable: deck.isSharedEditable
      }
    })
  ).then((generalDeckData) => {
    return API.graphql(
      graphqlOperation(createMyLearningDeck, {
        input: {
          deckId: generalDeckData.data.createGeneralDeck.id,
          userId: user.attributes.sub,
          numCardsPerRound: deck.numCardsPerRound
        }
      })
    ).then((myLearningDeckData) => {
      return {
        ...generalDeckData.data.createGeneralDeck,
        ...myLearningDeckData.data.createMyLearningDeck,
        owned_by_user: true
      };
    });
  });
};

/**
 * Update Deck like description, tags, and so one
 */
export const updateExistingDeck = async (userId, deck, props) => {
  const rejectPromise = validateDeckUpdate(deck, props);
  if (rejectPromise !== undefined) {
    return rejectPromise;
  }
  const updatedParamForOwner = isOwnerByOthersButShared(deck) ? {} : props;
  const init = {
    queryStringParameters: {
      userId: userId,
      deckId: deck.id,
      numCardsPerRound: props.numCardsPerRound,
      ...updatedParamForOwner
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.put(API_NAME, RestApiPath.DECK, init);
};

export const updateShareStatusForDeck = async (user, deck, props) => {
  const init = {
    queryStringParameters: {
      userId: user.attributes.sub,
      deckId: deck.id,
      isShared: props.isShared,
      isSharedEditable: props.isSharedEditable
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.put(API_NAME, RestApiPath.DECK, init);
};

export const deleteExistingDeck = async (user, deck) => {
  const init = {
    queryStringParameters: {
      userId: user.attributes.sub,
      deckId: deck.id
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.del(API_NAME, RestApiPath.DECK, init);
};

// ===================== Card operations =====================

export const addNewCard = async (
  cardProps,
  deckId,
  userId,
  currentCardSize
) => {
  const rejectPromise = validateCardCreation(cardProps, currentCardSize);
  if (rejectPromise !== undefined) {
    return rejectPromise;
  }
  return API.graphql(
    graphqlOperation(createGeneralCard, {
      input: {
        deckId: deckId,
        question: cardProps.question.trim(),
        answer: cardProps.answer.trim()
      }
    })
  ).then((generalCard) => {
    return API.graphql(
      graphqlOperation(createMyLearningCard, {
        input: {
          deckId: deckId,
          cardId: generalCard.data.createGeneralCard.id,
          userId: userId
        }
      })
    ).then((myLearningCard) => {
      return {
        ...generalCard.data.createGeneralCard,
        ...myLearningCard.data.createMyLearningCard
      };
    });
  });
};

/**
 * This is for someone shared a deck with you
 */
export const addMissingLearningDeck = async (deck, userId) => {
  if (deck.learning_deckId === undefined) {
    return API.graphql(
      graphqlOperation(createMyLearningDeck, {
        input: {
          deckId: deck.id,
          userId: userId,
          numCardsPerRound:
            deck.numCardsPerRound || DEFAULT_CARDS_TO_LEARN_PER_ROUND
        }
      })
    )
      .then((myLearningDeck) => {
        return myLearningDeck.data.createMyLearningDeck;
      })
      .then((myLearningDeck) => {
        console.log("addMissingLearningDeck", myLearningDeck);
        return { ...deck, ...myLearningDeck };
      });
  }
  return Promise.resolve(deck);
};

export const addMissingLearningCards = async (deckId, userId, cards) => {
  const missingCardIdsForLearning = cards
    .filter((c) => c.learning_cardId === undefined)
    .map((c) => c.id);

  const promises = missingCardIdsForLearning.map((cardId) => {
    return API.graphql(
      graphqlOperation(createMyLearningCard, {
        input: {
          deckId: deckId,
          cardId: cardId,
          userId: userId
        }
      })
    );
  });
  return Promise.all(promises).then((myLearningCards) => {
    return myLearningCards.map((myLearningCard) => {
      return myLearningCard.data.createMyLearningCard;
    });
  });
};

/**
 * Update Card question and answer. decId is better since sometime search page does not have deck
 */
export const updateExistingCard = async (userId, deckId, card, props) => {
  if (props.question.trim().length === 0 || props.answer.trim().length === 0) {
    return Promise.reject("Question and answer cannot be empty");
  }
  const init = {
    queryStringParameters: {
      deckId: deckId,
      userId: userId,
      cardId: card.id,
      question: props.question,
      answer: props.answer
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.put(API_NAME, RestApiPath.CARD, init);
};

export const deleteExistingCard = async (userId, deck, card) => {
  const init = {
    queryStringParameters: {
      deckId: deck.id,
      userId: userId,
      cardId: card.id
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.del(API_NAME, RestApiPath.CARD, init);
};

export const updateExistingCardSpacedRepetition = async (
  userId,
  deck,
  card,
  efactor,
  interval,
  repetition,
  superMemoGrade
) => {
  const init = {
    queryStringParameters: {
      deckId: deck.id,
      userId: userId,
      cardId: card.id,
      efactor,
      interval,
      repetition,
      recentSuperMemoGrade: superMemoGrade
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.put(API_NAME, RestApiPath.CARD, init).then((data) => {
    // Backend only upload learning deck, it does not return general deck
    return {
      card,
      ...data
    };
  });
};

/**
 * Search card and deck
 * @param searchTerm
 * @param userId
 * @returns {decks: [], cards: []}
 */
export async function searchLearningCardAndDeck(searchTerm, userId) {
  const init = {
    queryStringParameters: {
      userId: userId,
      searchTerm: searchTerm
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.get(API_NAME, RestApiPath.SEARCH, init).then(async (data) => {
    const cards = await Promise.all(
      data.cards.map(async (card) => {
        if (isInputStringImageEncoded(card.question)) {
          card.questionImage = await retrieveImageFromStorage(card.question);
        }
        return card;
      })
    );
    return {
      decks: data.decks,
      cards: cards
    };
  });
}
