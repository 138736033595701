import { DifficultyLevels, Limitation, SuperMemoGrade } from "../constant";

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export function superMemoToDayunDifficultLevel(superMemoGrade) {
  if (
    superMemoGrade ===
    SuperMemoGrade.INCORRECT_RESPONSE_WHERE_THE_CORRECT_ONE_SEEMED_EASY_TO_RECALL
  ) {
    return DifficultyLevels.Hard;
  } else if (
    superMemoGrade ===
    SuperMemoGrade.CORRECT_RESPONSE_RECALLED_WITH_SERIOUS_DIFFICULTY
  ) {
    return DifficultyLevels.Medium;
  } else if (
    superMemoGrade === SuperMemoGrade.CORRECT_RESPONSE_AFTER_HESITATION
  ) {
    return DifficultyLevels.Easy;
  } else {
    throw new Error("Not supported superMemoGrade: " + superMemoGrade);
  }
}

export function getNextReviewDate(item, today) {
  if (
    item.interval === null ||
    item.interval === undefined ||
    item.superMemoStartDateTime === null ||
    item.superMemoStartDateTime === undefined
  ) {
    return today;
  }
  return new Date(item.superMemoStartDateTime).addDays(item.interval);
}

export function filterCardPerRound(
  cards,
  numCardsPerRound = Limitation.MAX_CARDS_PER_DECK
) {
  function getNextReviewTime(card) {
    const lastLearningDate = card.lastLearningDate;
    const interval = card.interval || 0;
    if (!lastLearningDate) {
      // Never learnt before. So, it should be the first card to learn.
      console.log("Never learnt before" + card.id);
      return new Date(card.createdAt).getTime() - 315360000000;
    }

    const efactor = parseFloat(card.efactor || 1);
    const nextReviewTime =
      new Date(lastLearningDate).getTime() +
      24 * 3600 * 1000 * interval * efactor;
    card.nextReviewTime = nextReviewTime;
    return nextReviewTime;
  }

  const result = cards
    .sort((a, b) => {
      const aNextReviewTime = getNextReviewTime(a);
      const bNextReviewTime = getNextReviewTime(b);
      if (aNextReviewTime === bNextReviewTime) {
        return a.createdAt - b.createdAt;
      } else {
        return aNextReviewTime - bNextReviewTime;
      }
    })
    .slice(0, numCardsPerRound);
  return result;
}
