import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicDeck from "../dayun-ui-compontents/card/BasicDeck";
import CreateDeckDialog from "../dayun-ui-compontents/dialog/CreateDeckDialog";
import { Grid, Tab, Tabs } from "@mui/material";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  DeckTabName,
  DEFAULT_SUBJECT_NAME,
  Limitation,
  RoutePathName
} from "../constant";
import {
  addNewCard,
  addNewDeck,
  deleteExistingDeck,
  getDeckAndCards,
  getDecksAndCards,
  getDecksForUser
} from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress } from "@mui/material/";

const DUMMY_TEST_DECK_PREFIX = "TestDeck";

function TestPage({ signOut, user }) {
  const navigate = useNavigate();
  const [decks, setDecks] = useState(undefined);
  const [deckIdToLearningStats, setDeckIdToLearningStats] = useState(new Map());
  const [deckIdToCards, setDeckIdToCards] = useState(new Map());
  const [categories, setCategories] = useState(undefined);
  const [loading, setLoading] = useState(false);

  function groupAndSetCategories(decks) {
    const fetchedSubjectToDecks = {};
    for (const deck of decks) {
      const subject = deck.subject || DEFAULT_SUBJECT_NAME;
      if (fetchedSubjectToDecks[subject] === undefined) {
        fetchedSubjectToDecks[subject] = {
          name: subject,
          decks: []
        };
      }
      fetchedSubjectToDecks[subject].decks.push(deck);
    }
    // flatten the object to an array
    const categories = [];
    for (const subject in fetchedSubjectToDecks) {
      categories.push(fetchedSubjectToDecks[subject]);
    }
    setCategories(categories);
  }

  useEffect(() => {
    if (user === undefined || user === null) {
      return;
    }
    getDecksForUser(user)
      .then((data) => {
        setDecks(data.decks);
      })
      .catch((error) => {
        alertForApiError(error);
      })
      .finally(() => {});
  }, [user]);

  useEffect(() => {
    if (decks === undefined) {
      return;
    }
    const deckIds = decks.map((deck) => deck.id);
    getDecksAndCards(user, deckIds).then((data) => {
      const results = data.map((deckAndCards) => {
        return [deckAndCards.deck.id, deckAndCards.cards];
      });
      setDeckIdToCards(new Map(results));
    });
    setDeckIdToLearningStats(new Map());
    groupAndSetCategories(decks);
  }, [decks]);

  function createOneDummyTestDeck() {
    const dummyDeckName = DUMMY_TEST_DECK_PREFIX + -1;
    const deck = {
      name: dummyDeckName,
      description: "Test deck for testing",
      subject: "Test"
    };
    setLoading(true);
    addNewDeck(deck, user, 0)
      .then((newDeck) => {
        return addNewCard(
          {
            question: "Test question",
            answer: "Test answer"
          },
          newDeck.id,
          user,
          0
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createManyDummyTestDeck() {
    const promiseList = [];
    setLoading(true);
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    const delayTime = 100;

    const questions = [
      "What is the capital of France?",
      "What is the tallest mountain in the world?",
      "What is the largest continent on Earth?",
      "What is the chemical symbol for gold?",
      "Who invented the telephone?"
    ];

    const answers = [
      "Paris",
      "Mount Everest",
      "Asia",
      "Au",
      "Alexander Graham Bell"
    ];

    for (const i of Array(Limitation.MAX_DECKS_PER_USER).keys()) {
      const dummyDeckName = DUMMY_TEST_DECK_PREFIX + i;
      const deck = {
        name: dummyDeckName,
        description: "Test deck for testing" + i,
        subject: "Test" + (i % 5)
      };
      promiseList.push(
        addNewDeck(deck, user, 0).then((newDeck) => {
          for (const j of Array(Limitation.MAX_CARDS_PER_DECK).keys()) {
            const randomIndex = Math.floor(Math.random() * questions.length);
            const randomQuestion = questions[randomIndex];
            const randomAnswer = answers[randomIndex];
            const card = {
              question: randomQuestion,
              answer: randomAnswer
            };
            promiseList.push(
              delay(delayTime).then(() => addNewCard(card, newDeck.id, user, 0))
            );
          }
        })
      );
    }
    Promise.all(promiseList)
      .then(() => {
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function deleteAllDummyTestDeck() {
    const promiseList = [];
    setLoading(true);
    let index = 0;
    for (const deck of decks) {
      if (deck.name.includes(DUMMY_TEST_DECK_PREFIX)) {
        promiseList.push(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              console.log("delete deck: " + deck.name);
              deleteExistingDeck(user, deck);
            }, index * 100); // set delay between API calls to 0.2s
          })
        );
      }
      index += 1;
    }
    Promise.all(promiseList)
      .then((data) => {
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getDecks() {
    if (decks === undefined) {
      return null;
    }
    if (decks.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh"
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            align="center"
            sx={{
              color: "#deebf8",
              textAlign: "center",
              fontSize: "27px",
              "@media (max-width: 499px)": {
                fontSize: "20px"
              }
            }}
          >
            No deck found
          </Typography>
        </div>
      );
    }
    return (
      <Grid item xs={12}>
        {loading ? <CircularProgress /> : null}
        <Button onClick={(e) => createOneDummyTestDeck()} variant="outlined">
          Create one dummy large deck
        </Button>
        <Button onClick={(e) => createManyDummyTestDeck()} variant="outlined">
          Create 30(maximum per user) dummy large decks
        </Button>
        <Button onClick={(e) => deleteAllDummyTestDeck()} variant="outlined">
          Delete all test decks
        </Button>

        {categories &&
          categories.map((category, index) => (
            <Grid container spacing={1} key={index}>
              <Grid item xs={12} marginLeft={5} marginTop={6}>
                <Typography
                  variant="h4"
                  component="h4"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#deebf8",
                    "@media (max-width: 541px)": {
                      fontSize: "17px"
                    }
                  }}
                >
                  {category.name}
                </Typography>
              </Grid>
              {category.decks.map((deck, index) => (
                <Grid
                  container
                  justifyContent="left"
                  alignItems="left"
                  item
                  xs={12}
                  md={4}
                  sm={3}
                  lg={2}
                  xl={2}
                  marginLeft={3.71}
                  padding={{ xs: 1, md: 2 }}
                  key={deck.id}
                  marginTop={1}
                  sx={{
                    "@media (max-width: 541px)": {
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridGap: "10px",
                      padding: "17px",
                      marginRight: "24px"
                    }
                  }}
                >
                  <BasicDeck
                    user={user}
                    deck={deck}
                    cards={deckIdToCards.get(deck.id)}
                    onClickDeck={() => {}}
                    onClickDelete={() => {}}
                    onClickEdit={() => {}}
                    onClickShare={() => {}}
                    todayLearningStats={deckIdToLearningStats.get(deck.id)}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        paddingLeft: "60px",
        margin: "0 auto",
        "@media (max-width: 1281px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& > :not(style)": { m: 1 }
          }}
        >
          <Fab
            variant="extended"
            onClick={() => {}}
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              fontSize: "17.5px",
              backgroundColor: "#deebf8",
              position: "absolute",
              right: "120px",
              transition: "background-color 0.2s ease-in-out",
              "@media (min-width: 630px)": {
                display: "none"
              },
              "@media (max-width: 629px)": {
                visibility: "visible",
                fontSize: "14px",
                height: "30px",
                justifyContent: "center",
                position: "fixed",
                bottom: 60,
                right: 11,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <AddIcon sx={{ m: 1, "@media (max-width: 629px)": { m: 0.1 } }} />
          </Fab>
          <Fab
            variant="extended"
            sx={{
              color: "#282d35",
              fontWeight: "bold",
              fontSize: "17.5px",
              backgroundColor: "#deebf8",
              position: "absolute",
              right: "28px",
              transition: "background-color 0.2s ease-in-out",
              "@media (min-width: 630px)": {
                display: "none"
              },
              "@media (max-width: 629px)": {
                visibility: "visible",
                fontSize: "14px",
                height: "30px",
                justifyContent: "center",
                position: "fixed",
                bottom: 20,
                right: 11,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b5c5e5";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#deebf8";
            }}
          >
            <SystemUpdateAltIcon
              sx={{ m: 1, "@media (max-width: 629px)": { m: 0.1 } }}
            />
          </Fab>
        </Box>
        {getDecks()}
      </>
    </Box>
  );
}

export default withDayunAuthenticator(TestPage);
