import { Limitation } from "../constant";
import { isInputStringImageEncoded } from "./StorageUtil";

export const canDeckBeEditedByTheUser = (deck, user) => {
  return deck && (deck.owned_by_user || deck.isSharedEditable);
};

export const isOwnerByOthersButShared = (deck) => {
  if (
    deck.owned_by_user === false &&
    (Boolean(deck.isShared) === true || Boolean(deck.isSharedEditable === true))
  ) {
    return true;
  }
  return false;
};

export const validateDeckCreation = (deck, currentDeckSize) => {
  if (currentDeckSize > Limitation.MAX_DECKS_PER_USER) {
    return Promise.reject(
      `You have reached the maximum number of decks per user ${deck.name}`
    );
  }

  if (
    deck.name === undefined ||
    deck.name === null ||
    deck.name.length === 0 ||
    deck.name.length > Limitation.MAX_LEN_DECK_NAME
  ) {
    return Promise.reject("Invalid deck name");
  }

  if (
    deck.description !== undefined &&
    deck.description.length > Limitation.MAX_LEN_DECK_DESCRIPTION
  ) {
    return Promise.reject("Invalid deck description");
  }

  return undefined;
};
/**
 * Validate card creation, return reject promise if validation fails
 */
export const validateCardCreation = (cardProps, currentCardSize) => {
  if (currentCardSize > Limitation.MAX_CARDS_PER_DECK) {
    return Promise.reject(
      "You have reached the maximum number of cards per deck"
    );
  }
  if (
    cardProps.question === undefined ||
    cardProps.answer === undefined ||
    cardProps.question.trim().length === 0 ||
    cardProps.answer.trim().length === 0
  ) {
    return Promise.reject("Question and answer cannot be empty");
  }
  if (
    isInputStringImageEncoded(cardProps.question) === false &&
    cardProps.question.length > Limitation.MAX_LEN_QUESTION
  ) {
    return Promise.reject("Question is too long");
  }

  if (cardProps.question.length > Limitation.MAX_LEN_ANSWER) {
    return Promise.reject("Answer is too long");
  }
  return undefined;
};

export const validateDeckUpdate = (deck, props) => {
  if (
    props.name === undefined ||
    props.name === null ||
    props.name.length > Limitation.MAX_LEN_DECK_NAME
  ) {
    return Promise.reject("Invalid deck name");
  }
  if (
    props.description !== undefined &&
    props.description !== null &&
    props.description.length > Limitation.MAX_LEN_DECK_DESCRIPTION
  ) {
    return Promise.reject("Invalid deck description");
  }
  return undefined;
};
