import Typography from "@mui/material/Typography";

function NoItemFound({ msg }) {
  if (msg === undefined) {
    msg = "No cards need to be reviewed today";
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "20vh"
      }}
    >
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          color: "#deebf8",
          fontSize: "27px",
          "@media (max-width: 630px)": {
            fontSize: "18px"
          }
        }}
      >
        {msg}
      </Typography>
    </div>
  );
}

export default NoItemFound;
