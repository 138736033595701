import { DifficultyLevels } from "../constant";
import { superMemoToDayunDifficultLevel } from "./CardUtil";
import {calculatePercentage} from "./AchievementUtil";

/**
 * return  [
 *   { name: "Deck 1", mastery: 75, easy: 20, medium: 10, hard: 5 },
 *   { name: "Deck 2", mastery: 50, easy: 15, medium: 10, hard: 8 },
 *   { name: "Deck 3", mastery: 25, easy: 10, medium: 8, hard: 12 }
 * ]
 */
export function getAchievementForDeckAndItsCards(decksAndCards) {
  const result = [];
  for (const eachDeckCards of decksAndCards) {
    if (eachDeckCards.error !== undefined) {
      console.error("getDeckAssessment", eachDeckCards.error);
    } else {
      const deck = eachDeckCards.deck;
      const allCardsInDeck = eachDeckCards.cards || [];
      const cardSizeToCalculatePercentage = allCardsInDeck.length;
      let difficultLevels = getDifficultFromDeckSuperMemo(allCardsInDeck);
      const percentage =
        cardSizeToCalculatePercentage === 0
          ? 0
          : calculatePercentage(difficultLevels, cardSizeToCalculatePercentage);
      const easyCount = difficultLevels.filter(
        (l) => l === DifficultyLevels.Easy
      ).length;
      const mediumCount = difficultLevels.filter(
        (l) => l === DifficultyLevels.Medium
      ).length;
      const hardCount = difficultLevels.filter(
        (l) => l === DifficultyLevels.Hard
      ).length;

      result.push({
        id: deck.id,
        name: deck.name,
        mastery: percentage,
        easy: easyCount,
        medium: mediumCount,
        hard: hardCount,
        totalDifficultyCount: difficultLevels.length
      });
    }
  }
  return result;
}

export function getDifficultFromDeckSuperMemo(
  allCardsInDeck,
  cardsToDifficultyThisRound = undefined
) {
  const difficultLevels = [];
  for (const card of allCardsInDeck) {
    let difficulty = cardsToDifficultyThisRound
      ? cardsToDifficultyThisRound.get(card.id)
      : undefined;
    // Cards exist in the deck but not in the round
    if (difficulty === undefined) {
      if (card.recentSuperMemoGrade === undefined) {
        // Never study before
        continue;
      } else {
        difficulty = superMemoToDayunDifficultLevel(card.recentSuperMemoGrade);
      }
    }
    difficultLevels.push(difficulty);
  }
  return difficultLevels;
}
