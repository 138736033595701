import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DEFAULT_CARDS_TO_LEARN_PER_ROUND, Limitation } from "../../constant";
import { canDeckBeEditedByTheUser } from "../../utils/ValidationUtil";

export default function CreateDeckDialog({
  user,
  open,
  onCloseDialog,
  onCreateDeck,
  currentDeck
}) {
  const [numCardsPerRound, setNumCardsPerRound] = useState(
    DEFAULT_CARDS_TO_LEARN_PER_ROUND
  );
  const [name, setName] = useState(currentDeck && currentDeck.name);
  const [description, setDescription] = useState(
    currentDeck && currentDeck.description
  );
  const [subject, setSubject] = useState(currentDeck && currentDeck.subject);
  const [isCreationBtnDisabled, setIsCreationBtnDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (currentDeck === undefined) {
      // When creating a deck, there is no need to check if the user can edit the deck
      setCanEdit(true);
      return;
    }
    if (canDeckBeEditedByTheUser(currentDeck, user)) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [user, currentDeck]);

  useEffect(() => {
    if (currentDeck === undefined) {
      return;
    }
    setName(currentDeck ? currentDeck.name : undefined);
    setDescription(currentDeck ? currentDeck.description : undefined);
    setSubject(currentDeck ? currentDeck.subject : undefined);
    setNumCardsPerRound(
      currentDeck.numCardsPerRound !== undefined
        ? parseInt(currentDeck.numCardsPerRound)
        : DEFAULT_CARDS_TO_LEARN_PER_ROUND
    );
  }, [currentDeck, open]);

  const dialogTitle = currentDeck ? "Edit deck" : "Add a deck";

  const resetForm = () => {
    setName("");
    setDescription("");
    setSubject("");
    setNumCardsPerRound(DEFAULT_CARDS_TO_LEARN_PER_ROUND);
  }

  function handleClickSaveBtn() {
    if (
      numCardsPerRound !== undefined &&
      numCardsPerRound > Limitation.MAX_CARDS_PER_DECK
    ) {
      alert(
        "The number of cards to study each round should be less than " +
          Limitation.MAX_CARDS_PER_DECK
      );
      return;
    }
    if (subject === undefined || subject === "") {
      alert("Please enter a subject");
      return;
    }

    if (name === undefined || name === "") {
      alert("Please enter a name");
      return;
    }

    if (isCreationBtnDisabled) {
      return;
    }

    setIsCreationBtnDisabled(true);
    onCreateDeck({
      name,
      description,
      subject,
      numCardsPerRound
    });
    resetForm();
    setTimeout(() => {
      setIsCreationBtnDisabled(false);
    }, 1000);
  }


  const handleCloseDialog = () => {
    resetForm();
    onCloseDialog();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            background: "#deebf8",
            color: "#282d35",
            fontSize: "31px",
            fontWeight: "bold",
            textAlign: "left",
            "@media (max-width: 499px)": {
              fontSize: "25px"
            }
          }}
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{ background: "#deebf8" }}>
          <TextField
            disabled={!canEdit}
            autoFocus
            margin="dense"
            id="Subject"
            label="Subject"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={currentDeck ? currentDeck.subject : undefined}
            value={subject}
            inputProps={{ maxLength: Limitation.MAX_LEN_DECK_SUBJECT }}
            onChange={(e) => setSubject(e.target.value)}
          />

          <TextField
            disabled={!canEdit}
            margin="dense"
            id="Name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={currentDeck ? currentDeck.name : undefined}
            value={name}
            inputProps={{ maxLength: Limitation.MAX_LEN_DECK_NAME }}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            disabled={!canEdit}
            margin="dense"
            id="Description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={currentDeck ? currentDeck.description : undefined}
            value={description}
            inputProps={{ maxLength: Limitation.MAX_LEN_DECK_DESCRIPTION }}
            onChange={(e) => setDescription(e.target.value)}
          />

          <TextField
            style={{ marginTop: "12px" }}
            label="Number of cards to study per round"
            defaultValue={DEFAULT_CARDS_TO_LEARN_PER_ROUND}
            fullWidth
            type="number"
            variant="standard"
            value={parseInt(numCardsPerRound)}
            inputProps={{ min: 1, max: Limitation.MAX_CARDS_PER_DECK }}
            onChange={(e) => {
              if (
                e.target.value === "" ||
                e.target.value === null ||
                e.target.value === undefined
              ) {
                alert(
                  "The number of cards to study each round should be greater than 0"
                );
              }
              setNumCardsPerRound(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions style={{ background: "#deebf8" }}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            disabled={isCreationBtnDisabled}
            onClick={() => {
              handleClickSaveBtn();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
