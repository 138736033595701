import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  Button,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete, Edit, Share } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import ShareDeckDialog from "../dialog/ShareDeckDialog";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useNavigate } from "react-router-dom";
import { canDeckBeEditedByTheUser } from "../../utils/ValidationUtil";
import { RoutePathName } from "../../constant";

export function getDeckMaterialUICardContent(
  onClickDeck,
  deck,
  cards,
  learningProgress
) {
  function renderCardSize() {
    if (cards === undefined || cards === null) {
      return undefined;
    }
    const cardSize = cards && cards.length;
    const cardSizeText = cardSize === 1 ? "card" : "cards";
    return (
      <Typography
        sx={{
          mb: 1,
          "@media (max-width: 499px)": {
            fontSize: "16px"
          }
        }}
        color="text.secondary"
      >
        {cardSize} {cardSizeText}
      </Typography>
    );
  }

  function renderLearningCards() {
    if (
      learningProgress === undefined ||
      learningProgress === null ||
      learningProgress.totalCardsToday === undefined
    ) {
      return undefined;
    }
    const totalCardsToday =
      learningProgress && learningProgress.totalCardsToday;
    return (
      <Typography sx={{ mb: 1 }} color="text.secondary">
        {totalCardsToday} cards studied today
      </Typography>
    );
  }

  return (
    <CardContent
      onClick={() => onClickDeck()}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#b5c5e5"
        }
      }}
    >
      <Typography
        gutterBottom
        variant="h2"
        component="div"
        sx={{
          color: "#282d35",
          fontSize: "30px",
          fontWeight: "400",
          "@media (max-width: 499px)": {
            fontSize: "21px",
            fontWeight: "400"
          }
        }}
      >
        {deck.name}
      </Typography>
      {renderCardSize()}
      {renderLearningCards()}

      {/*   {learningProgress !== undefined && learningProgress !== null ? (
        <Typography sx={{ mb: 1 }} color="text.secondary">
          {learningProgress === 0
            ? "Not started today"
            : `Today: Studied  card(s). ${learningProgress.todayStudiedNewCards} out of ${learningProgress.totalCardsToday} cards are new`}
        </Typography>
      ) : null}*/}

      <Typography variant="body1">{deck.description}</Typography>
    </CardContent>
  );
}

export default function BasicDeck({
  user,
  onClickDelete,
  onClickEdit,
  onClickShare,
  todayLearningStats,
  deck,
  cards
}) {
  const [learningProgress, setLearningProgress] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (canDeckBeEditedByTheUser(deck, user)) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [user, deck]);

  const CommonDialogStyle = {
    background: "#deebf8",
    color: "#282d35",
    fontSize: "31px",
    fontWeight: "bold",
    textAlign: "left",
    "@media (max-width: 499px)": {
      fontSize: "25px"
    }
  };
  const styles = {
    iconContainer: {
      display: "flex",
      alignItems: "center"
    },
    iconButton: {
      marginRight: "8px"
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const handleShareOpen = () => {
    setAnchorEl(null);
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
  };

  const [sharedLink, setSharedLink] = useState(
    `${window.location.href}/${deck.id}`
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
    handleClose();
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    const totalStudiedCardsToday = todayLearningStats
      ? todayLearningStats.length
      : 0;
    if (totalStudiedCardsToday > 0) {
      setLearningProgress({
        totalCardsToday: totalStudiedCardsToday
      });
    }
  }, [todayLearningStats]);

  function handleEditClick() {
    onClickEdit();
    setAnchorEl(null);
  }

  function handleDeleteClick() {
    onClickDelete();
    setAnchorEl(null);
  }

  function handleClickDeck() {
    if (canEdit && cards && cards.length === 0) {
      navigate(`${RoutePathName.DECK}/${deck.id}`);
    } else {
      navigate(`${RoutePathName.LEARN}/${deck.id}`);
    }
  }

  return (
    <>
      <Card
        sx={{
          padding: "5px",
          borderRadius: "10px",
          width: "100%",
          background: "#deebf8",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#b5c5e5"
          },
          color: "#282d35",
          height: "100%"
        }}
      >
        <div style={styles.iconContainer}>
          <Tooltip title="Edit the cards in this deck" arrow placement="top">
            <IconButton
              onClick={() => navigate(`/deck/${deck.id}`)}
              aria-label="Edit"
              style={styles.iconButton}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Study the cards in this deck" arrow placement="top">
            <IconButton
              onClick={() => navigate(`/learn/${deck.id}`)}
              aria-label="Study"
              style={styles.iconButton}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit / Remove deck and more" arrow placement="top">
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ float: "right", marginLeft: "auto" }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEditClick}>
            <Edit style={{ marginRight: "0.5em", color: "#282d35" }} />
            Edit deck
          </MenuItem>
          <MenuItem onClick={handleShareOpen}>
            <Share style={{ marginRight: "0.5em", color: "#282d35" }} />
            Share deck
          </MenuItem>

          {canEdit ? (
            <MenuItem disabled={!canEdit} onClick={handleConfirmOpen}>
              <Delete style={{ marginRight: "0.5em", color: "#282d35" }} />
              Delete deck
            </MenuItem>
          ) : undefined}
        </Menu>
        <Dialog open={confirmOpen} onClose={handleConfirmClose}>
          <DialogTitle
            sx={{
              ...CommonDialogStyle
            }}
          >
            Confirm Deletion
          </DialogTitle>
          <DialogContent style={{ background: "#deebf8", color: "#282d35" }}>
            <DialogContentText
              sx={{
                fontSize: "18px",
                "@media (max-width: 499px)": {
                  fontSize: "16px",
                  textAlign: "left"
                }
              }}
            >
              Are you sure you want to delete this deck? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ background: "#deebf8", color: "#282d35" }}>
            <Button onClick={handleConfirmClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteClick} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <ShareDeckDialog
          deck={deck}
          open={shareOpen}
          onCloseDialog={handleShareClose}
          onClickShare={onClickShare}
        />
        {getDeckMaterialUICardContent(
          handleClickDeck,
          deck,
          cards,
          learningProgress
        )}
      </Card>
    </>
  );
}
