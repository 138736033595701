import React, {useEffect} from "react";
import { Button, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import BoxOne from "./BoxOne.png";
import BoxTwo from "./BoxTwo.png";
import BoxThree from "./BoxThree.png";
import { useNavigate } from "react-router-dom";
import { RoutePathName } from "../constant";
import PlayDownload from "./PlayDownload.png";
import {Auth} from "aws-amplify";

const styles = {
  heroContent: {
    backgroundColor: "#282d35",
    padding: "64px 0 50px",
    textAlign: "center"
  },
  heroHeader: {
    marginBottom: "35px",
    color: "#deebf8",
    fontSize: "100px",
    fontWeight: "bold"
  },
  heroButtons: {
    marginTop: "40px"
  },
  feature: {
    marginTop: "20px",
    padding: "40px",
    textAlign: "center",
    backgroundColor: "#deebf8",
    color: "#282d35",
    marginLeft: "15%",
    marginRight: "15%",
    height: "275px",
    "@media (max-width: 1024px)": {
      marginTop: "10px",
      fontSize: "1rem",
      height: "500px"
    },
    "@media (max-width: 912px)": {
      marginTop: "10px",
      fontSize: "1rem",
      height: "500px"
    }
  }
};

function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      if (user) {
        navigate(RoutePathName.DECKS);
      }
    });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "58px 0 50px",
            textAlign: "center",
            backgroundColor: "#282d35"
          }}
        >
          <Typography component="h1" variant="h3" style={styles.heroHeader}>
            Study smarter
          </Typography>
          <Typography component="h1" variant="h3" style={styles.heroHeader}>
            Not harder
          </Typography>
          <Typography
            variant="h5"
            color="#deebf8"
            style={{ marginTop: "20px", marginBottom: "10px" }}
          >
            Say goodbye to conventional flashcard apps and embrace the future of
            learning with our innovative web app
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px"
            }}
          >
            <Button
              onClick={() => {
                navigate(RoutePathName.DECKS);
              }}
              variant="outlined"
              style={{
                backgroundColor: "#5ce1e6",
                color: "#282d35",
                fontFamily: "Yantramanav, sans-serif",
                fontSize: "22px",
                width: "240px",
                height: "60px",
                padding: "10px",
                transition: "background-color 0.2s ease-in-out"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#7af4f9";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#5ce1e6";
              }}
            >
              Get Started Now
            </Button>
            <div
              style={{
                width: "240px",
                height: "60px",
                marginTop: "25px",
                overflow: "hidden"
              }}
            >
              <a href="https://play.google.com/store/apps/details?id=com.dayun.flashlearn&pli=1">
                <img
                  src={PlayDownload}
                  alt="download"
                  style={{ width: "68%", height: "68%", objectFit: "cover" }}
                />
              </a>
            </div>
          </div>
        </div>
        <Grid container spacing={4} style={{ display: "flex" }}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              flexGrow: 1
            }}
          >
            <Paper
              sx={{
                padding: "40px",
                textAlign: "center",
                backgroundColor: "#deebf8",
                color: "#282d35",
                marginLeft: "auto",
                marginRight: "auto",
                height: "300px",
                width: "70%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={BoxOne}
                alt="BoxOne"
                style={{
                  maxWidth: "90%",
                  maxHeight: "100px",
                  objectFit: "cover"
                }}
              />
              <Typography
                component="h2"
                variant="h5"
                fontWeight="bold"
                style={{ marginTop: "10px", fontSize: "1.6rem" }}
              >
                Create and Import
              </Typography>
              <Typography variant="subtitle1">
                Easily create, import and share flashcards for better learning
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              flexGrow: 1
            }}
          >
            <Paper
              sx={{
                marginTop: "0px",
                padding: "40px",
                textAlign: "center",
                backgroundColor: "#deebf8",
                color: "#282d35",
                marginLeft: "auto",
                marginRight: "auto",
                height: "300px",
                width: "70%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={BoxTwo}
                alt="BoxTwo"
                style={{
                  maxWidth: "52%",
                  maxHeight: "110px",
                  objectFit: "cover"
                }}
              />
              <Typography
                component="h2"
                variant="h5"
                fontWeight="bold"
                style={{ fontSize: "1.6rem" }}
              >
                Knowledge Keeper
              </Typography>
              <Typography variant="subtitle1">
                Effortlessly retain information with advanced spaced repetition
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} style={{ flexGrow: 1 }}>
            <Paper
              sx={{
                marginTop: "0px",
                padding: "40px",
                textAlign: "center",
                backgroundColor: "#deebf8",
                color: "#282d35",
                marginLeft: "auto",
                marginRight: "auto",
                height: "300px",
                width: "70%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={BoxThree}
                alt="BoxThree"
                style={{
                  maxWidth: "39%",
                  maxHeight: "110px",
                  objectFit: "cover"
                }}
              />
              <Typography
                component="h2"
                variant="h5"
                fontWeight="bold"
                style={{ fontSize: "1.6rem" }}
              >
                AI Answer Generator
              </Typography>
              <Typography variant="subtitle1">
                Transform Your Question-Answering Experience with AI Technology
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          borderTop: "1px solid #deebf8",
          justifyContent: "center",
          width: "90.5%",
          bottom: 20,
          marginTop: "50px",
          padding: "20px 0",
          marginRight: "5%",
          marginLeft: "5%"
        }}
      >
        <Typography
          variant="body2"
          color="#deebf8"
          style={{ marginTop: "0px", textAlign: "left" }}
        >
          © {new Date().getFullYear()} DaYun Tech
        </Typography>
      </div>
    </div>
  );
}

export default LandingPage;
