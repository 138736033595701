import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsConfig from "./aws-exports";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import DeckPage from "./pages/DeckPage";
import Navbar from "./dayun-ui-compontents/navbar/Navbar";
import DecksDashboard from "./pages/DecksDashboard";
import SearchPage from "./pages/SearchPage";
import { RoutePathName } from "./constant";
import Profile from "./pages/Profile";
import PublicDecksDashboard from "./pages/PublicDecksDashboard";
import LearningPage from "./pages/LearningPage";
import Achievement from "./pages/Achievement";
import SharedDecksDashboard from "./pages/SharedDecksDashboard";
import TestPage from "./pages/TestPage";
import Privacy from "./pages/Privacy";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const enableDebug = isLocalhost || window.location.pathname.includes("main");
if (!enableDebug) {
  console.log = () => {};
}

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut
  }
};

Amplify.configure(updatedAwsConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AmplifyProvider>
    <BrowserRouter>
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path={RoutePathName.DECKS} element={<DecksDashboard />} />
          <Route path={`${RoutePathName.DECK}/:id`} element={<DeckPage />} />
          <Route
            path={`${RoutePathName.LEARN}/:id`}
            element={<LearningPage />}
          />
          <Route
            path={RoutePathName.PUBLIC}
            element={<PublicDecksDashboard />}
          />
          <Route
            path={`${RoutePathName.SEARCH}/:term`}
            element={<SearchPage />}
          />
          <Route path={RoutePathName.CONTACT} element={<Contact />} />
          <Route path={RoutePathName.PROFILE} element={<Profile />} />
          <Route path={RoutePathName.ACHIEVEMENT} element={<Achievement />} />
          <Route path={RoutePathName.PRIVACY} element={<Privacy />} />
          <Route
            path={`${RoutePathName.SHARE_DECKS}`}
            element={<SharedDecksDashboard />}
          />
          <Route path="*" element={<PageNotFound />} />
          {enableDebug && (
            <Route path={RoutePathName.TEST_PAGE} element={<TestPage />} />
          )}
        </Routes>
      </div>
    </BrowserRouter>
  </AmplifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
