import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { withDayunAuthenticator } from "../hoc/withDayunAuthenticator";
import Box from "@mui/material/Box";
import {
  DeckOwnerType,
  DeckTabName,
  DEFAULT_SUBJECT_NAME,
  RoutePathName
} from "../constant";
import Typography from "@mui/material/Typography";
import {
  getDeckAndCards,
  getDecksForUser,
  updateExistingDeck
} from "../utils/ApiUtil";
import { alertForApiError } from "../utils/AlertUtil";
import ProgressDialog from "../dayun-ui-compontents/dialog/ProgressDialog";
import BasicDeck from "../dayun-ui-compontents/card/BasicDeck";
import CreateDeckDialog from "../dayun-ui-compontents/dialog/CreateDeckDialog";
import NoItemFound from "../dayun-ui-compontents/NoItemFound";

function ShareDecksDashboard({ signOut, user }) {
  const navigate = useNavigate();
  const [selectedTab] = useState(DeckTabName.SHARE_DECK_NAME);
  const [decks, setDecks] = useState(undefined);
  const [deckIdToLearningStats, setDeckIdToLearningStats] = useState(new Map());
  const [deckIdToCards, setDeckIdToCards] = useState(new Map());
  const [categories, setCategories] = useState(undefined);
  const [editedDeck, setEditedDeck] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 1000);
    getDecksForUser(user, DeckOwnerType.SHARED_BY_OTHERS)
      .then((data) => {
        setDecks(data.decks);

        const fetchedSubjectToDecks = {};
        for (const deck of data.decks) {
          const subject = deck.subject || DEFAULT_SUBJECT_NAME;
          if (fetchedSubjectToDecks[subject] === undefined) {
            fetchedSubjectToDecks[subject] = {
              name: subject,
              decks: []
            };
          }
          fetchedSubjectToDecks[subject].decks.push(deck);
        }
        // flatten the object to an array
        const categories = [];
        for (const subject in fetchedSubjectToDecks) {
          categories.push(fetchedSubjectToDecks[subject]);
        }
        setCategories(categories);
      })
      .catch((error) => {
        alertForApiError(error);
      })
      .finally(() => {
        clearTimeout(timeoutId);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (decks === undefined) {
      return;
    }
    Promise.all(
      decks.map((deck) => {
        return getDeckAndCards(user, deck.id).then((data) => {
          const d = data.deck;
          const cards = data.cards;
          return [d.id, cards];
        });
      })
    ).then((results) => {
      const deckIdToCards = new Map(results);
      setDeckIdToCards(deckIdToCards);
    });

    setDeckIdToLearningStats(new Map());
  }, [decks]);

  function handleEditDeck(deck, props) {
    updateExistingDeck(user.attributes.sub, deck, props)
      .then((updatedDeck) => {
        console.log("Deck updated", updatedDeck);
        setDecks(
          decks.map((d) => {
            if (d.id === updatedDeck.id) {
              return { ...d, ...updatedDeck };
            }
            return d;
          })
        );
      })
      .catch((e) => {
        alertForApiError(e);
      })
      .finally(() => {
        setEditedDeck(undefined);
      });
  }

  const goToOtherPageWhenClickingTab = (value) => {
    if (value !== DeckTabName.SHARE_DECK_NAME) {
      navigate(RoutePathName.DECKS);
    }
  };

  return loading ? (
    <ProgressDialog open={loading} title={"Loading..."} />
  ) : (
    <Grid
      sx={{
        position: "relative",
        paddingLeft: "60px",
        margin: "0 auto",
        "@media (max-width: 1281px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      }}
    >
      <CreateDeckDialog
        user={user}
        currentDeck={editedDeck}
        onCreateDeck={(props) => handleEditDeck(editedDeck, props)}
        onCloseDialog={() => setEditedDeck(undefined)}
        open={editedDeck !== undefined}
      />

      <Grid item xs={12}>
        <Box style={{ position: "relative" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => goToOtherPageWhenClickingTab(newValue)}
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#5271ff" },
              "& .MuiTab-root": { color: "#deebf8" },
              "& .Mui-selected": { color: "#5ce1e6" }
            }}
          >
            <Tab
              label="My Decks"
              value="My Decks"
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "24px",
                marginLeft: "23px",
                marginTop: "28px",
                "@media (max-width: 630px)": {
                  fontSize: "16.5px",
                  width: "38%",
                  height: "40px",
                  marginTop: "10px",
                  justifyContent: "center",
                  marginLeft: "10px"
                }
              }}
            />
            <Tab
              label="Shared Decks"
              value="Shared Decks"
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "23px",
                marginLeft: "20px",
                marginTop: "28px",
                "@media (max-width: 630px)": {
                  fontSize: "16.5px",
                  width: "46%",
                  height: "40px",
                  marginTop: "10px",
                  justifyContent: "center",
                  position: "absolute",
                  left: "140px"
                }
              }}
            />
          </Tabs>

          {categories !== undefined && categories.length === 0 ? (
            <NoItemFound msg={"No shared deck found"} />
          ) : null}

          {categories &&
            categories.map((category, index) => (
              <Grid container spacing={1} key={index}>
                <Grid item xs={12} marginLeft={5} marginTop={6}>
                  <Typography
                    variant="h4"
                    component="h4"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      color: "#deebf8",
                      "@media (max-width: 630px)": {
                        fontSize: "17px"
                      }
                    }}
                  >
                    {category.name}
                  </Typography>
                </Grid>
                {category.decks.map((deck, index) => (
                  <Grid
                    container
                    justifyContent="left"
                    alignItems="left"
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    lg={2}
                    xl={2}
                    marginLeft={3.71}
                    padding={{ xs: 1, md: 2 }}
                    key={deck.id}
                    marginTop={1}
                    sx={{
                      "@media (max-width: 700px)": {
                        gridTemplateColumns: "repeat(1, 1fr)",
                        gridGap: "10px",
                        padding: "40px",
                        marginRight: "24px"
                      }
                    }}
                  >
                    <BasicDeck
                      user={user}
                      deck={deck}
                      cards={deckIdToCards.get(deck.id)}
                      onClickDeck={() => {}}
                      onClickDelete={() => {
                        console.log(
                          "should not delete the deck in shared decks"
                        );
                      }}
                      onClickEdit={() => setEditedDeck(deck)}
                      onClickShare={() => {}}
                      todayLearningStats={deckIdToLearningStats.get(deck.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default withDayunAuthenticator(ShareDecksDashboard);
