import {TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import UndoIcon from "@mui/icons-material/Undo";
import ClearIcon from "@mui/icons-material/Clear";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import React from "react";

export function getCardQAEditWebForm(index, canEdit, card, setEditingCardIndex, cards, setCards, handleDiscard, setDeleteDeleteConfirmOpen, handleClickSaveCard) {
  return <div
    key={index}
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#deebf8",
      marginBottom: "1rem",
      padding: "1rem",
      borderRadius: "10px"
    }}
  >
    <div style={{marginRight: "1rem", flex: 1}}>
      <TextField
        sx={{
          color: "#282d35",
          backgroundColor: "#deebf8"
        }}
        disabled={!canEdit}
        value={card.question}
        fullWidth
        multiline
        wrap="soft"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography fontWeight="bold" component="span">
                Q:
              </Typography>
            </InputAdornment>
          ),
          readOnly: false
        }}
        onChange={(e) => {
          setEditingCardIndex(index);
          const newCards = [...cards];
          newCards[index].question = e.target.value;
          setCards(newCards);
        }}
      />

      <div>
        <TextField
          sx={{
            color: "#282d35",
            backgroundColor: "#deebf8"
          }}
          disabled={!canEdit}
          value={card.answer}
          fullWidth
          multiline
          wrap="soft"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography fontWeight="bold" component="span">
                  A:
                </Typography>
              </InputAdornment>
            ),
            readOnly: false
          }}
          onChange={(e) => {
            setEditingCardIndex(index);
            const newCards = [...cards];
            newCards[index].answer = e.target.value;
            setCards(newCards);
          }}
        />
      </div>
    </div>

    <div
      style={{
        position: "relative",
        transform: "translateY(-50%)"
      }}
    >
      <Fab
        disabled={!canEdit}
        color="#282d35"
        onClick={(e) => handleDiscard(e, card)}
        sx={{
          float: "right",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#deebf8",
          position: "absolute",
          top: -15,
          right: -16,
          padding: 0,
          width: "26px",
          height: "26px",
          minHeight: "30px",
          minWidth: "30px",
          "&:hover::before": {
            content: '"Discard changes"',
            position: "absolute",
            top: "-22px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "3px",
            fontSize: "12px",
            backgroundColor: "#282d35",
            color: "#deebf8",
            borderRadius: "4px",
            whiteSpace: "nowrap"
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#b5c5e5";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#deebf8";
        }}
      >
        <UndoIcon/>
      </Fab>

      <Fab
        disabled={!canEdit}
        onClick={() => {
          setEditingCardIndex(index);
          setDeleteDeleteConfirmOpen(true);
        }}
        sx={{
          float: "right",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#deebf8",
          position: "absolute",
          top: -65,
          right: -16,
          padding: 0,
          width: "26px",
          height: "26px",
          minHeight: "30px",
          minWidth: "30px",
          "&:hover::before": {
            content: '"Delete this card"',
            position: "absolute",
            top: "-22px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "3px",
            fontSize: "12px",
            backgroundColor: "#282d35",
            color: "#deebf8",
            borderRadius: "4px",
            whiteSpace: "nowrap"
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#b5c5e5";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#deebf8";
        }}
      >
        <ClearIcon/>
      </Fab>
      <Fab
        disabled={!canEdit}
        color="#282d35"
        onClick={(e) => handleClickSaveCard(e, index)}
        sx={{
          float: "right",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#deebf8",
          position: "absolute",
          right: -16,
          padding: 0,
          top: 30,
          width: "26px",
          height: "26px",
          minHeight: "30px",
          minWidth: "30px",
          "&:hover::before": {
            content: '"Save changes"',
            position: "absolute",
            top: "-22px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "3px",
            fontSize: "12px",
            backgroundColor: "#282d35",
            color: "#deebf8",
            borderRadius: "4px",
            whiteSpace: "nowrap"
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#b5c5e5";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#deebf8";
        }}
      >
        <SaveAltIcon/>
      </Fab>
    </div>
  </div>;
}